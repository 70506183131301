import store from "../store";
import config from "../config";

export default async () => {
    if (store.state.isAuth) {
        return true;
        // tried to be auth once and failed
    } else if (store.state.isAuth === false) {
        throw new Error("Logged once");
        // not auth and never tried to auth before
    } else {
        const jwtToken    = window.localStorage.getItem(config.token);
        const isSuperUser = window.localStorage.getItem(config.superuser);

        // has a jwt token
        if (jwtToken) {
            await store.dispatch("VERIFY", { jwtToken, isSuperUser });
            // no jwt token
        } else {
            throw new Error("No jwt token found");
        }
    }
};
