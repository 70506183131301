<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.31 32.913"><path d="M24.976 27.636a.679.679 0 00-.679.679v2.564a.677.677 0 01-.677.677H2.035a.677.677 0 01-.678-.677v-21.9l7.62-7.62H23.62a.677.677 0 01.677.677v9.667a.679.679 0 001.357 0V2.036A2.035 2.035 0 0023.62.001H8.696a.679.679 0 00-.48.2L.198 8.219a.679.679 0 00-.2.48v22.182a2.035 2.035 0 002.035 2.035h21.586a2.035 2.035 0 002.034-2.035v-2.564a.679.679 0 00-.677-.681z"/><path d="M8.018 4.069v3.949H4.069a.679.679 0 000 1.357h4.628a.679.679 0 00.679-.679V4.068a.679.679 0 10-1.357 0zM32.714 14.437a2.022 2.022 0 00-1.439-.6H12.931a2.035 2.035 0 00-2.034 2.035v8.427a2.035 2.035 0 002.034 2.035h18.344a2.035 2.035 0 002.035-2.035v-8.427a2.022 2.022 0 00-.596-1.435zm-.761 9.866a.677.677 0 01-.678.677H12.931a.677.677 0 01-.677-.677v-8.427a.677.677 0 01.677-.677h18.344a.678.678 0 01.678.677z"/><path d="M19.64 17.247a.678.678 0 00-.953.109l-1.307 1.643-1.307-1.643a.679.679 0 10-1.062.845l1.5 1.889-1.5 1.889a.679.679 0 101.062.845l1.307-1.643 1.307 1.643a.679.679 0 001.062-.845l-1.5-1.889 1.5-1.889a.679.679 0 00-.109-.954zM24.119 21.721h-2.223v-3.944a.679.679 0 10-1.357 0v4.622a.679.679 0 00.678.679h2.9a.679.679 0 100-1.357zM27.139 18.456a1.415 1.415 0 011.045.437.679.679 0 10.96-.96 2.781 2.781 0 00-2-.834 1.9 1.9 0 00-2.124 1.866c0 1.416 1.353 1.669 2 1.791.9.168.9.283.9.457 0 .338-.258.509-.767.509a1.414 1.414 0 01-1.045-.437.679.679 0 00-.96.96 2.781 2.781 0 002 .834 1.9 1.9 0 002.125-1.866c0-1.416-1.353-1.669-2-1.791-.9-.168-.9-.283-.9-.457-.001-.338.257-.509.766-.509z"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconCloudXls'
  };
</script>
