import Vue from "vue";
import VeeValidate from "vee-validate";

const dictionary = {
  fr: {
    messages: {
      required() {
        return `Ce champ est requis`;
      },
      email() {
        return `L'adresse email renseignée n'est pas valide`;
      },
      numeric() {
        return `Seul les nombres sont autorisés`;
      },
      min(field, min) {
        return `Ce champ nécessite au minimum ${min} caractères`;
      },
      max(field, max) {
        return `Ce champ doit être composé de ${max} caractères maximum`;
      },
      max_value(field, max) {
        return `Ce champ doit avoir une valeur de ${max} ou moins`;
      },
      alpha_num() {
        return `Les caractères spéciaux ne sont pas autorisés`;
      },
      alpha_spaces() {
        return `Les nombres et caractères spéciaux ne sont pas autorisés`;
      },
      url() {
        return `L'URL renseigné n'est pas valide`;
      },
      telephone() {
        return `Le numéro de téléphone renseigné n'est pas valide`;
      },
      decimal() {
        return `Les nombres décimaux sont autorisés`;
      },
      is() {
        return `Les mots de passe ne correspondent pas`;
      },
      regex() {
        return `Les nombres et caractères spéciaux ne sont pas autorisés`;
      },
    },
  },
};

/* Valider les numéro de téléphone dans différents formats : +33, 01, 555-555-666, 06.85.45.65.65 */
const telephoneValidator = {
  getMessage() {
    return `Le numéro de téléphone renseigné n'est pas valide`;
  },
  validate(value) {
    return /(0|\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1))((\W*\d\W*){1,10})$/.test(
      value
    );
  },
};

VeeValidate.Validator.extend("telephone", telephoneValidator);
VeeValidate.Validator.localize(dictionary);
//VeeValidate.Validator.localize("fr",dictionary.fr);

Vue.use(VeeValidate, {
  fieldsBagName: "vvFields",
  locale: "fr",
  dictionary: dictionary,
});
