<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.964 22.692"><g data-name="Groupe 1207"><path data-name="Tracé 859" d="M16.192 8.037H15.6V5.355A5.362 5.362 0 0010.245 0H7.719a5.362 5.362 0 00-5.355 5.355v2.682h-.591A1.775 1.775 0 000 9.81v11.11a1.775 1.775 0 001.773 1.773h14.419a1.775 1.775 0 001.773-1.773V9.81a1.775 1.775 0 00-1.773-1.773M3.309 5.355a4.415 4.415 0 014.41-4.41h2.526a4.415 4.415 0 014.41 4.41v2.682h-1.418V5.6A3.239 3.239 0 0010 2.364H7.963A3.239 3.239 0 004.727 5.6v2.437H3.309zm2.364 2.682V5.6a2.293 2.293 0 012.29-2.29H10a2.293 2.293 0 012.29 2.29v2.437zm-3.9.945h14.419a.828.828 0 01.827.827v9.573H.945V9.81a.828.828 0 01.827-.827m10.242 12.763L10.6 20.328h1.972l1.418 1.418zm-3.309 0l-1.418-1.418h1.972l1.418 1.418zm-3.309 0l-1.418-1.418H5.95l1.418 1.418zm-4.45-.827v-.591h1.7l1.418 1.418H1.773a.828.828 0 01-.827-.827m15.246.827h-.868l-1.418-1.418h3.114v.591a.828.828 0 01-.827.827"/><path data-name="Tracé 860" d="M7.373 15.155l-.355 2.492a.49.49 0 00.484.558h2.936a.489.489 0 00.484-.553l-.33-2.495a2.445 2.445 0 10-3.217 0m1.61-3.305a1.466 1.466 0 01.814 2.686.49.49 0 00-.213.47l.294 2.222H8.065l.316-2.217a.491.491 0 00-.212-.475 1.466 1.466 0 01.814-2.686"/></g></svg>
</template>

<script>
  export default {
    name : 'MeepIconLock'
  };
</script>
