<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.417 17.289"><path fill="none" stroke="#000" stroke-miterlimit="10" d="M9.884.5a6.305 6.305 0 00-6.06 4.929A5.96 5.96 0 00.5 10.816a5.857 5.857 0 005.735 5.973h11.99a4.8 4.8 0 004.692-4.887 4.885 4.885 0 00-2.631-4.344c.007-.091.024-.174.024-.271a4 4 0 00-3.91-4.072 3.686 3.686 0 00-1.311.263A6.16 6.16 0 009.884.5"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconCloud'
  };
</script>
