<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.503 27.94"><path d="M13.221 3.475l3.409-2.218a.684.684 0 00-.746-1.146L11.213 3.15a.684.684 0 00-.2.946l3.023 4.647a.684.684 0 101.146-.746l-2.053-3.156a10.878 10.878 0 11-5.506.995.684.684 0 10-.582-1.237 12.256 12.256 0 106.179-1.122z"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconRestore'
  };
</script>
