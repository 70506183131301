export default {
  showChat: false,
  showConversation: false,
  isShowCloudEmployee: false,
  isLoadMessageMore: false,
  currentChatId: "",
  site_name: "",
  iOsUrl: "",
  androidUrl: "",

  conversations: [],
  messages: [],

  notifications: [],
  unreadMessagesCount: 0,
  canChat: false,

  // use to auth user
  isAuth: null,
  isAuthLoading: false,
  isMobile: false,
  showSnackbar: false,

  token: "",

  // user informations
  self: {},

  socketId: "",
  socketSynced: false,

  color_scheme: 0,
  logo: null,
  logoMinified: null,
  organization_id: "",

  //import data
  newUsers: [],
  newCompanies: [],
  importErrorLine: false,
};
