<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.309 32.913"><path d="M24.976 27.636a.679.679 0 00-.679.679v2.564a.677.677 0 01-.677.677H2.034a.677.677 0 01-.677-.677v-21.9l7.62-7.62H23.62a.677.677 0 01.677.677v9.668a.679.679 0 001.357 0V2.036A2.035 2.035 0 0023.619.001H8.695a.678.678 0 00-.479.2L.198 8.219a.679.679 0 00-.2.48v22.182a2.035 2.035 0 002.035 2.034h21.586a2.035 2.035 0 002.035-2.034v-2.564a.679.679 0 00-.678-.681z"/><path d="M8.018 4.069v3.949H4.069a.679.679 0 100 1.357h4.628a.679.679 0 00.679-.679V4.068a.679.679 0 00-1.357 0zM32.713 14.437a2.022 2.022 0 00-1.438-.6H12.931a2.035 2.035 0 00-2.034 2.034v8.427a2.035 2.035 0 002.034 2.035h18.344a2.035 2.035 0 002.035-2.035v-8.427a2.023 2.023 0 00-.597-1.434zm-.761 9.866a.678.678 0 01-.678.677H12.93a.677.677 0 01-.677-.677v-8.427a.677.677 0 01.677-.677h18.344a.677.677 0 01.678.677z"/><path d="M17.095 17.097h-1.972a.679.679 0 00-.679.679v4.622a.679.679 0 101.357 0v-1.015h1.294a2.116 2.116 0 002.113-2.113v-.06a2.116 2.116 0 00-2.113-2.113zm.756 2.173a.757.757 0 01-.756.756h-1.294v-1.572h1.294a.757.757 0 01.756.756zM29.084 19.65h-1.047a.679.679 0 100 1.357h.368v.53a1.354 1.354 0 01-2.036-1.169v-.558a1.354 1.354 0 012.257-1.008.679.679 0 10.907-1.009 2.711 2.711 0 00-4.521 2.017v.558a2.711 2.711 0 004.527 2.012.68.68 0 00.224-.5v-1.548a.679.679 0 00-.679-.682zM23.982 17.097a.679.679 0 00-.678.679v2.712l-2.552-3.14a.679.679 0 00-1.205.428v4.622a.679.679 0 101.357 0v-2.712l2.552 3.14a.678.678 0 001.2-.428v-4.622a.679.679 0 00-.674-.679z"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconCloudPng'
  };
</script>
