<template>
  <div class="md-list md-theme-default">
    <md-list-item
      v-for="menu in sideBarUser"
      v-bind:key="menu.id"
      :class="menu.class"
      :to="menu.to"
      data-test-list-item
    >
      <component :is="menu.icon" :class="menu.classIcon"></component>
      <span
        class="md-list-item-text"
        v-html="$t(menu.title)"
        data-test-list-title
      />
    </md-list-item>
  </div>
</template>
<script>
import MeepIconChat from "@/components/icons/MeepIconChat";
import MeepIconCloud from "@/components/icons/MeepIconCloud";
import MeepIconTask from "@/components/icons/MeepIconTask";
import MeepIconListAccounting from "@/components/icons/MeepIconListAccounting";
import MeepIconNews from "@/components/icons/MeepIconNews";
import MeepIconNotes from "@/components/icons/MeepIconNotes";
import MeepIconSocial from "@/components/icons/MeepIconSocial.vue";
import MeepIconSimulateurs from "@/components/icons/MeepIconSimulateurs.vue";
import JEPAIconTax from "@/components/icons/JEPAIconTax";
import MeepIconSettings from "@/components/icons/MeepIconSettings";
import { Menu } from "@/dto/menu.enum";

export default {
  name: "NormalUserMenu",
  components: {
    MeepIconSettings,
    JEPAIconTax,
    MeepIconTask,
    MeepIconCloud,
    MeepIconSocial,
    MeepIconChat,
    MeepIconListAccounting,
    MeepIconNews,
    MeepIconNotes,
    MeepIconSimulateurs,
  },
  props: {
    listIdMenu: {
      type: Array,
      require: true,
    },
  },

  data() {
    return {
      sideBarUserMenu: [
        {
          id: Menu.SETTING,
          icon: MeepIconSettings,
          title: "menu.setting",
          to: "/dashboard/setting/",
          class: "dash-menu__list-item",
          classIcon: "dash-menu__icon",
        },
        {
          id: Menu.SOCIALS,
          title: "menu.socials",
          icon: MeepIconSocial,
          to: "/dashboard/sociale/",
          class: "dash-menu__list-item",
          classIcon: "dash-menu__icon",
        },
        {
          id: Menu.CHAT,
          title: "menu.chat",
          icon: MeepIconChat,
          to: "/dashboard/communicate/",
          class: "dash-menu__list-item dash-menu__list-item--chat",
          classIcon: "dash-menu__icon",
        },
        {
          id: Menu.CLOUD,
          title: "menu.cloud",
          icon: MeepIconCloud,
          to: "/dashboard/cloud/",
          class: "dash-menu__list-item dash-menu__list-item--cloud",
          classIcon: "dash-menu__icon--stroke",
        },
        {
          id: Menu.TAX,
          title: "menu.tax",
          icon: JEPAIconTax,
          to: "/dashboard/tax/",
          class: "dash-menu__list-item",
          classIcon: "dash-menu__icon",
        },
        {
          id: Menu.TASK,
          title: "menu.task",
          icon: MeepIconTask,
          to: "/dashboard/tasks/tab-own",
          class: "dash-menu__list-item dash-menu__list-item--task",
          classIcon: `dash-menu__icon--stroke ${
            this.isJEP || this.isCE ? "app-icon-contains-g" : ""
          }`,
        },
        {
          id: Menu.NOTES,
          title: "menu.expense-reports",
          icon: MeepIconNotes,
          to: "/dashboard/expense-reports/",
          class: "dash-menu__list-item",
          classIcon: "dash-menu__icon",
        },
        {
          id: Menu.ACCOUNTING_PLAN,
          title: "menu.accouting-plan",
          icon: MeepIconListAccounting,
          to: "/dashboard/accounting-plan/menu",
          class: "dash-menu__list-item",
          classIcon: "dash-menu__icon",
        },
        {
          id: Menu.SIMULATEURS,
          title: "menu.simulateurs",
          icon: MeepIconSimulateurs,
          to: "/dashboard/simulateurs",
          class: "dash-menu__list-item",
          classIcon: "dash-menu__icon",
        },
        {
          id: Menu.NEWS,
          title: "menu.news",
          icon: MeepIconNews,
          to: "/dashboard/news/",
          class: "dash-menu__list-item dash-menu__list-item--news",
          classIcon: "dash-menu__icon--stroke",
        },
        {
          id: Menu.SETTING_JEPA,
          icon: MeepIconSettings,
          title: "menu.setting-jepa",
          to: "/dashboard/tax/",
          class: "dash-menu__list-item",
          classIcon: "dash-menu__icon",
        },
        {
          id: Menu.CHAT_JEPA,
          title: "menu.chat",
          icon: MeepIconChat,
          to: "/dashboard/communicate/chat/",
          class: "dash-menu__list-item dash-menu__list-item--chat",
          classIcon: "dash-menu__icon",
        },
      ],
    };
  },

  computed: {
    sideBarUser() {
      return this.listIdMenu.map((menu) => {
        return this.sideBarUserMenu.find((sideBar) => sideBar.id === menu);
      });
    },
  },
};
</script>
