<template>
  <section
    :class="{ 'no-item-card': isCard, 'no-item-card--ce': isCE }"
    class="no-item"
  >
    <slot>
      <MeepIconNoItems class="app-icon" />
    </slot>
    <h1 v-if="title">{{ title }}</h1>
    <h1 v-else>Oh !</h1>
    <p class="no-item__text" v-html="text" />
    <md-button
      v-if="buttonText"
      class="md-primary"
      @click="clickText ? onClick(clickText) : onClick()"
    >
      {{ buttonText }}
    </md-button>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import MeepIconNoItems from "@/components/icons/MeepIconNoItems";

export default {
  name: "NoItem",

  components: {
    MeepIconNoItems,
  },
  props: {
    text: {
      type: String,
      default: "No items",
    },
    title: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
    isCard: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
    },
    clickText: {
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["isJEPA", "isJEP", "isCE", "isLCM"]),
  },
  mounted() {
  },
  methods: {},
};
</script>

<style lang="scss">
@import "../styles/_variable.scss";

.no-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 1rem;

  .app-icon {
    height: 45px;
    width: 55px;
  }

  &.no-item-card--ce {
    h1 {
      font-size: 35px;
    }
  }

  h1 {
    font-family: var(--font-bold);
    font-size: 39px;
    margin: toRem(14) 0 toRem(10);
    @include for-lg {
      margin: toRem(40) 0 toRem(20);
    }
  }

  &__text {
    font-size: toRem(13);
    text-align: center;
    @include for-lg {
      font-size: toRem(17);
    }
  }

  &-card {
    height: 260px;
    background: #fff;
    border-radius: 27px;
    @include for-lg {
      height: 376px;
    }

    .md-primary {
      margin-top: toRem(26);
      padding: 0.5rem 1rem;
      font-size: toRem(9);
      min-height: unset;
      height: 29px;
      @include for-lg {
        margin-top: 40px;
        padding: 0 50px;
        font-size: toRem(14);
        height: 44px;
      }
    }
  }
}
</style>
