import fetch from "../services/fetch";
import store from "../store";

export default {
  getAll,
  read,
  readAll,
  remove,
  removeAll,
};

function getAll() {
  let queryParams = `?unreadOnly=${true}`;

  return fetch("api/notifications" + queryParams, {
    headers: { Authorization: store.state.token },
  });
}

function read(id) {
  return fetch("api/notifications/read", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id,
    }),
  });
}

function readAll(readChatOnly) {
  return fetch("api/notifications/read-all", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      readChatOnly,
    }),
  });
}

function remove(id) {
  return fetch("api/notifications/" + id, {
    method: "delete",
    headers: { Authorization: store.state.token },
  });
}

function removeAll(readChatOnly) {
  let queryParams = "";
  if (readChatOnly) {
    queryParams += `?readChatOnly=${readChatOnly}`;
  }

  return fetch("api/notifications" + queryParams, {
    method: "delete",
    headers: { Authorization: store.state.token },
    body: JSON.stringify({
      readChatOnly,
    }),
  });
}
