export const Menu = {
    SETTING: 1,
    SOCIALS: 2,
    CHAT: 3,
    CLOUD: 4,
    TAX: 5,
    TASK: 6,
    NOTES: 7,
    ACCOUNTING_PLAN: 8,
    SIMULATEURS: 9,
    NEWS: 10,
    SETTING_JEPA: 11,
    CHAT_JEPA: 12,
}