import Vue from "vue";
import VueMaterial from "vue-material";

import "vue-material/dist/vue-material.min.css";
//import 'vue-material/dist/theme/default.css'

Vue.use(VueMaterial);

Vue.material.locale = {
  ...Vue.material.locale,
  dateFormat: "dd/MM/yyyy",
  dates: [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ],
  shortDays: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  shorterDays: ["D", "L", "M", "M", "J", "V", "S"],
  months: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  shortMonths: [
    "Jan",
    "Fév",
    "Mar",
    "Avr",
    "Mai",
    "Jun",
    "Jul",
    "Aoû",
    "Sep",
    "Oct",
    "Nov",
    "Déc",
  ],
  shorterMonths: [
    "J",
    "F",
    "M",
    "A",
    "M",
    "Ju",
    "Ju",
    "A",
    "Se",
    "O",
    "N",
    "D",
  ],
  cancel: "Annuler",
  confirm: "Valider",
};
