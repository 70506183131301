<template>
  <div class="md-list md-theme-default">
    <md-list-item
      v-for="menu in sideBarMenu"
      v-bind:key="menu.id"
      :class="menu.class"
      :to="menu.to"
      data-test-list-item
    >
      <md-icon class="dash-menu__icon-text">{{ menu.titleIcon }}</md-icon>
      <span class="md-list-item-text">{{ $t(menu.title) }}</span>
    </md-list-item>
  </div>
</template>
<script>
import { SuperMenu } from "@/dto/super-menu.enum";

export default {
  name: "SuperUserMenu",
  props: {
    listIdMenu: {
      type: Array,
      require: true,
    },
  },

  data() {
    return {
      sideBarUserMenu: [
        {
          id: SuperMenu.ORGANIZATIONS,
          title: "menu.organizations",
          titleIcon: "business_center",
          to: "/admin/organizations/",
          class: "dash-menu__list-item",
        },
        {
          id: SuperMenu.ADMINISTRATORS,
          title: "menu.administrators",
          titleIcon: "supervisor_account",
          to: "/admin/administrators/",
          class: "dash-menu__list-item",
        },
        {
          id: SuperMenu.CALCULATORS,
          title: "menu.calculators",
          titleIcon: "functions",
          to: "/admin/calculators/",
          class: "dash-menu__list-item",
        },
        {
          id: SuperMenu.TRASH,
          title: "menu.trash",
          titleIcon: "delete",
          to: "/admin/trash/",
          class: "dash-menu__list-item",
        },
      ],
    };
  },

  computed: {
    sideBarMenu() {
      return this.listIdMenu.map((menu) => {
        return this.sideBarUserMenu.find((sideBar) => sideBar.id === menu);
      });
    },
  },
};
</script>
