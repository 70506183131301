<template>
  <transition-group class="popups" name="fade" tag="div">
    <div
      class="popup"
      v-for="(popup) in popups"
      :key="popup.id"
      :class="popup.type"
    >
      <div class="icon-container">
        <i class="material-icons popup-icon">{{
          getIcon(popup.type, popup.id)
        }}</i>
      </div>
      <div class="text-container">
        {{ popup.text || getDefaultText(popup) }}
      </div>
      <div class="action-container">
        <md-button
          class="popup-btn md-icon-button delete-popup"
          @click.native="removePopup(popup.id)"
        >
          <md-icon>close</md-icon>
        </md-button>
      </div>
    </div>
  </transition-group>
</template>

<script>
export default {
  data() {
    return {
      current_id: "",
      modalIsVisible: false,
    };
  },

  computed: {
    popups() {
      return this.$store.state.popups.popups;
    },
  },
  methods: {
    removePopup(id) {
      this.$store.dispatch("popups/REMOVE", id);
    },

    getIcon(type, id) {
      this.current_id = id;
      switch (type) {
        case "success":
          return "check_circle";
        case "error":
          return "error";
        case "info":
          return "info";
      }
    },

    getDefaultText(popup) {
      if (popup.type === "error") {
        return "Une erreur est survenue, veuillez réessayer plus tard";
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss">
$iconContainerWidth: 70;

.popups {
  display: flex;
  flex-direction: column-reverse;
  position: fixed;
  bottom: 16px;
  left: 16px;
  z-index: 9999999;

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
    transform: translateX(20px);
  }

  .fade-leave-active {
    transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .popup {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 10px;
    min-height: 70px;
    width: 355px;
    background-color: white;
    box-shadow: 0 6px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 17px 0 rgba(0, 0, 0, 0.2);

    &.error {
      .icon-container {
        background-color: #ea384d;
      }
    }

    &.success {
      .icon-container {
        background-color: #a4c639;
      }
    }

    &.info {
      .icon-container {
        background-color: #72bcd4;
      }
    }
  }

  .icon-container {
    position: absolute;
    width: $iconContainerWidth + px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .popup-icon {
    font-size: 50px;
    color: white;
  }

  .text-container {
    margin-left: $iconContainerWidth + px;
    padding: 10px 12px 10px 12px;
    font-size: 15px;
    line-height: 1.4;
    flex: 1;
    word-wrap: break-word;
    overflow: hidden;
  }

  .action-container {
    padding-right: 10px;
  }

  .popup-btn {
    margin: 0;
  }
}
</style>
