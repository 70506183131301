const getApiURL = () => {
  return process.env.VUE_APP_API_URL || "https://preprod-api.meep-appli.fr/";
};

module.exports = {
  token: "ea3Rge3",
  superuser: "meep-superuser",
  authTimeout: 1750,
  apiUrl: getApiURL(),
  expertPlus: {
    url: "https://expertplus.expertsa.net/api/",
    key: "4IMQ6J1Ar74705583PmUKG6G25638xmsZdEGihpsxd2eYPb2QkUSW",
  },
  sitekeyRecaptcha: "6Ldx0eAZAAAAAF_bYjD1bTo8F8DJ3C28BkhRxo5D",
};
