import Vue from "vue";
import VueRouter from "vue-router";

import store from "./store";
import authMiddleware from "./services/auth";
import { USERS } from "./constants";

const Signin = () => import("./routes/signin.vue");
const Signup = () => import("./routes/signup.vue");
const AdminSignin = () => import("./routes/admin/signin.vue");
const Contact = () => import("./routes/Contact.vue");
const forgotPassword = () => import("./routes/forgot-password.vue");
const forgotPasswordAdmin = () => import("./routes/admin/forgot-password.vue");
const resetPassword = () => import("./routes/reset-password.vue");
const resetPasswordAdmin = () => import("./routes/admin/reset-password.vue");

//-- ADMINISTRATION --
const administratorsList = () =>
  import("./routes/admin/administrators/list.vue");
const administratorsUpdate = () =>
  import("./routes/admin/administrators/update.vue");
const administratorsCreate = () =>
  import("./routes/admin/administrators/create.vue");
const administratorsView = () =>
  import("./routes/admin/administrators/view.vue");

const organizationsList = () => import("./routes/admin/organizations/list.vue");
const organizationsUpdate = () =>
  import("./routes/admin/organizations/UpdateOrganization.vue");
const organizationsCreate = () =>
  import("./routes/admin/organizations/CreateOrganization.vue");
const organizationsView = () => import("./routes/admin/organizations/view.vue");

const superUserUpdate = () => import("./routes/admin/superusers/update.vue");
const superUserView = () => import("./routes/admin/superusers/view.vue");
const superUserChangePassword = () =>
  import("./routes/admin/superusers/change-password.vue");

// -- CALCULATRICES --
const calculatorsList = () => import("./routes/admin/calculators/index.vue");

const impotRevenuList = () =>
  import("./routes/admin/calculators/impot-revenu/list.vue");
const impotRevenuCreate = () =>
  import("./routes/admin/calculators/impot-revenu/create.vue");
const impotRevenuUpdate = () =>
  import("./routes/admin/calculators/impot-revenu/update.vue");
const impotRevenuHistory = () =>
  import("./routes/admin/calculators/impot-revenu/history.vue");

const impotSocieteList = () =>
  import("./routes/admin/calculators/impot-societes/list.vue");
const impotSocieteCreate = () =>
  import("./routes/admin/calculators/impot-societes/create.vue");
const impotSocieteUpdate = () =>
  import("./routes/admin/calculators/impot-societes/update.vue");
const impotSocieteHistory = () =>
  import("./routes/admin/calculators/impot-societes/history.vue");

const indemniteKilometriqueList = () =>
  import("./routes/admin/calculators/indemnite-kilometrique/list.vue");
const indemniteKilometriqueCreate = () =>
  import("./routes/admin/calculators/indemnite-kilometrique/create.vue");
const indemniteKilometriqueUpdate = () =>
  import("./routes/admin/calculators/indemnite-kilometrique/update.vue");
const indemniteKilometriqueHistory = () =>
  import("./routes/admin/calculators/indemnite-kilometrique/history.vue");

const salaireList = () => import("./routes/admin/calculators/salaire/list.vue");
const salaireCreate = () =>
  import("./routes/admin/calculators/salaire/create.vue");
const salaireUpdate = () =>
  import("./routes/admin/calculators/salaire/update.vue");
const salaireHistory = () =>
  import("./routes/admin/calculators/salaire/history.vue");

const emissionList = () =>
  import("./routes/admin/calculators/taxe-vehicule-societe-emission/list.vue");
const emissionCreate = () =>
  import(
    "./routes/admin/calculators/taxe-vehicule-societe-emission/create.vue"
  );
const emissionUpdate = () =>
  import(
    "./routes/admin/calculators/taxe-vehicule-societe-emission/update.vue"
  );
const emissionHistory = () =>
  import(
    "./routes/admin/calculators/taxe-vehicule-societe-emission/history.vue"
  );

const carburantList = () =>
  import("./routes/admin/calculators/taxe-vehicule-societe-carburant/list.vue");
const carburantCreate = () =>
  import(
    "./routes/admin/calculators/taxe-vehicule-societe-carburant/create.vue"
  );
const carburantUpdate = () =>
  import(
    "./routes/admin/calculators/taxe-vehicule-societe-carburant/update.vue"
  );
const carburantHistory = () =>
  import(
    "./routes/admin/calculators/taxe-vehicule-societe-carburant/history.vue"
  );

const trashAdminIndex = () => import("./routes/admin/trash/index.vue");
const trashOrganizations = () =>
  import("./routes/admin/trash/organizations.vue");

//-- DASHBOARD --

const Dashboard = () => import("./routes/Dashboard.vue");
const Information = () => import("./routes/Informations.vue");

const usersListPage = () => import("./routes/users/ListUserPage.vue");
const usersCreate = () => import("./routes/users/CreateUser.vue");
const usersUpdate = () => import("./routes/users/UpdateUser.vue");
const usersView = () => import("./routes/users/ViewUser.vue");
const usersApprove = () => import("./routes/users/ApproveUser.vue");
const usersChangePassword = () => import("./routes/users/ChangePassword.vue");
const usersImport = () => import("./routes/users/ImportUsers.vue");

const companiesList = () => import("./routes/companies/ListCompany.vue");
const companiesCreate = () => import("./routes/companies/CreateCompany.vue");
const companiesUpdate = () => import("./routes/companies/UpdateCompany.vue");
const companiesView = () => import("./routes/companies/ViewCompany.vue");
const companiesImport = () => import("./routes/companies/ImportCompanies.vue");

const groupesList = () => import("./routes/groupes/ListGroup.vue");
const groupesCreate = () => import("./routes/groupes/CreateGroup.vue");
const groupesUpdate = () => import("./routes/groupes/UpdateGroup.vue");
const groupesView = () => import("./routes/groupes/ViewGroup.vue");

const cloudBrowse = () => import("./routes/cloud/Cloud.vue");

const pushsList = () => import("./routes/pushs/ListPush.vue");
const pushsCreate = () => import("./routes/pushs/CreatePush.vue");

const chatList = () => import("./routes/chat/list.vue");

const tasksList = () => import("./routes/tasks/ListTask.vue");
const tasksCreate = () => import("./routes/tasks/CreateTask.vue");
const tasksUpdate = () => import("./routes/tasks/UpdateTask.vue");
const tasksView = () => import("./routes/tasks/ViewTask.vue");

const newsView = () => import("./routes/news/ViewNews.vue");
const ListNews = () => import("./routes/news/ListNews");

const homePage = () => import("./routes/home/Homepage.vue");
const Notifications = () => import("./routes/Notifications.vue");

const organizationNewsCreate = () =>
  import("./routes/organization-news/CreateOrganizationNews.vue");
const organizationNewsView = () =>
  import("./routes/organization-news/ViewOrganizationNews.vue");
const organizationNewsList = () =>
  import("./routes/organization-news/ListOrganizationNews.vue");
const organizationNewsUpdate = () =>
  import("./routes/organization-news/UpdateOrganizationNews.vue");

const ExpenseReportListCompanies = () =>
  import("./routes/expense-notes/expense-report-list-companies.vue");

const ExpenseReportListEmployee = () =>
  import("./routes/expense-notes/expense-report-list-employee.vue");

const Socials = () => import("./routes/socials/Socials.vue");
const FinDeContrat = () => import("./routes/socials/FinDeContrat.vue");
const MesPayes = () => import("./routes/socials/MesPayes.vue");
const Arret = () => import("./routes/socials/Arret.vue");
const Reprise = () => import("./routes/socials/Reprise.vue");
const Embauche = () => import("./routes/socials/Embauche.vue");

const Simulateurs = () => import("./routes/simulateurs/Simulateurs.vue");
const Credit = () => import("./routes/simulateurs/Credit.vue");
const Frais = () => import("./routes/simulateurs/Frais.vue");
const ImpotSur = () => import("./routes/simulateurs/ImpotSur.vue");
const ImpotSociete = () => import("./routes/simulateurs/ImpotSociete.vue");
const Tva = () => import("./routes/simulateurs/TVA.vue");
const Salary = () => import("./routes/simulateurs/Salary.vue");
const Marge = () => import("./routes/simulateurs/Marge.vue");
const Taxe = () => import("./routes/simulateurs/Taxe.vue");

const selectOrganization = () => import("./routes/select-organization.vue");

const AccountingMenu = () =>
  import("./routes/accounting-plan/AccountingMenu.vue");
const AccountingView = () =>
  import("./routes/accounting-plan/AccountingView.vue");

const Setting = () => import("./routes/settings/Setting.vue");
const Import = () => import("./routes/settings/Import.vue");

const Communicate = () => import("./routes/communicate/Communicate.vue");

const TermOfUse = () => import("./routes/TermsOfUse.vue");
const PersonalData = () => import("./routes/PersonalData.vue");
const CGV = () => import("./routes/CGV.vue");
const FAQ = () => import("./routes/FAQ.vue");
const Tax = () => import("./routes/tax/Tax.vue");
const FiscalYear = () => import("./routes/settings/FiscalYear/FiscalYear.vue");
Vue.use(VueRouter);

export const routes = [
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/politique-confidentialite",
    component: PersonalData,
    meta: {
      auth: false
    }
  },
  {
    path: "/signin",
    component: Signin,
  },
  {
    path: "/signup",
    component: Signup,
  },
  {
    path: "/forgot-password",
    component: forgotPassword,
  },
  {
    path: "/reset-password/:token",
    component: resetPassword,
  },
  {
    path: "/logout",
    beforeEnter(to, from, next) {
      store.dispatch("DESYNC_SOCKET");
      store.commit("LOGIN_ERROR");
      next("/signin");
    },
  },
  {
    path: "/signin-admin",
    component: AdminSignin,
  },
  {
    path: "/forgot-password-admin",
    component: forgotPasswordAdmin,
  },
  {
    path: "/reset-password-admin/:token",
    component: resetPasswordAdmin,
  },
  {
    path: "/select-organization",
    component: selectOrganization,
    meta: {
      auth: true,
      scope: [USERS.EXTERNAL],
    },
  },
  {
    path: "/admin",
    component: Dashboard,
    meta: {
      auth: true,
    },
    children: [
      {
        path: "organizations/",
        component: organizationsList,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "organizations/create",
        component: organizationsCreate,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "organizations/update/:id",
        component: organizationsUpdate,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "organizations/view/:id",
        component: organizationsView,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "administrators/",
        component: administratorsList,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "administrators/create",
        component: administratorsCreate,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "administrators/update/:id",
        component: administratorsUpdate,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "administrators/view/:id",
        component: administratorsView,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "superusers/change-password",
        component: superUserChangePassword,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "superusers/update/:id",
        component: superUserUpdate,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "superusers/view/:id",
        component: superUserView,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "calculators/",
        component: calculatorsList,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "calculators/impot-revenu/list/",
        component: impotRevenuList,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "calculators/impot-revenu/create",
        component: impotRevenuCreate,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "calculators/impot-revenu/update/:id",
        component: impotRevenuUpdate,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "calculators/impot-revenu/history",
        component: impotRevenuHistory,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "calculators/impot-societes/list",
        component: impotSocieteList,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "calculators/impot-societes/create",
        component: impotSocieteCreate,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "calculators/impot-societes/update/:id",
        component: impotSocieteUpdate,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "calculators/impot-societes/history",
        component: impotSocieteHistory,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "calculators/indemnite-kilometrique/list",
        component: indemniteKilometriqueList,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "calculators/indemnite-kilometrique/create",
        component: indemniteKilometriqueCreate,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "calculators/indemnite-kilometrique/update/:id",
        component: indemniteKilometriqueUpdate,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "calculators/indemnite-kilometrique/history",
        component: indemniteKilometriqueHistory,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "calculators/salaire/list",
        component: salaireList,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "calculators/salaire/create",
        component: salaireCreate,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "calculators/salaire/update/:id",
        component: salaireUpdate,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "calculators/salaire/history",
        component: salaireHistory,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "calculators/taxe-vehicule-societe-emission/list",
        component: emissionList,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "calculators/taxe-vehicule-societe-emission/create",
        component: emissionCreate,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "calculators/taxe-vehicule-societe-emission/update/:id",
        component: emissionUpdate,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "calculators/taxe-vehicule-societe-emission/history",
        component: emissionHistory,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "calculators/taxe-vehicule-societe-carburant/list",
        component: carburantList,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "calculators/taxe-vehicule-societe-carburant/create",
        component: carburantCreate,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "calculators/taxe-vehicule-societe-carburant/update/:id",
        component: carburantUpdate,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "calculators/taxe-vehicule-societe-carburant/history",
        component: carburantHistory,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "trash/",
        component: trashAdminIndex,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
      {
        path: "trash/organizations",
        component: trashOrganizations,
        meta: {
          scope: [USERS.SUPERUSER],
        },
      },
    ],
  },
  {
    path: "/dashboard",
    redirect: "/dashboard/home",
    component: Dashboard,
    meta: {
      auth: true,
    },
    children: [
      {
        path: "home/",
        component: homePage,
        meta: {
          scope: [
            USERS.ADMIN,
            USERS.COLLAB,
            USERS.EXTERNAL,
            USERS.EMPLOYEE,
            USERS.CLIENT,
          ],
        },
      },
      {
        path: "terms-of-use/",
        component: TermOfUse,
        meta: {
          scope: [
            USERS.ADMIN,
            USERS.COLLAB,
            USERS.EXTERNAL,
            USERS.EMPLOYEE,
            USERS.CLIENT,
          ],
        },
      },
      {
        path: "cgv/",
        component: CGV,
        meta: {
          scope: [
            USERS.ADMIN,
            USERS.COLLAB,
            USERS.EXTERNAL,
            USERS.EMPLOYEE,
            USERS.CLIENT,
          ],
        },
      },
      {
        path: "tax/",
        component: Tax,
        meta: {
          scope: [
            USERS.ADMIN,
            USERS.COLLAB,
            USERS.EXTERNAL,
            USERS.EMPLOYEE,
            USERS.CLIENT,
          ],
        },
      },
      {
        path: "faq/",
        component: FAQ,
        meta: {
          scope: [
            USERS.ADMIN,
            USERS.COLLAB,
            USERS.EXTERNAL,
            USERS.EMPLOYEE,
            USERS.CLIENT,
          ],
        },
      },
      {
        path: "contact/",
        component: Contact,
        meta: {
          scope: [
            USERS.ADMIN,
            USERS.COLLAB,
            USERS.EXTERNAL,
            USERS.EMPLOYEE,
            USERS.CLIENT,
          ],
        },
      },

      {
        path: "cloud/",
        name: "cloud-root",
        component: cloudBrowse,
        meta: {
          scope: [
            USERS.ADMIN,
            USERS.COLLAB,
            USERS.EXTERNAL,
            USERS.EMPLOYEE,
            USERS.CLIENT,
          ],
        },
      },
      {
        path: "cloud/:id",
        name: "cloud-detail",
        component: cloudBrowse,
        meta: {
          scope: [
            USERS.ADMIN,
            USERS.COLLAB,
            USERS.EXTERNAL,
            USERS.EMPLOYEE,
            USERS.CLIENT,
          ],
        },
      },
      {
        path: "notifications",
        component: Notifications,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.EXTERNAL, USERS.CLIENT],
        },
      },
      {
        path: "communicate/",
        component: Communicate,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.CLIENT, USERS.EMPLOYEE],
        },
      },
      {
        path: "communicate/chat/",
        component: chatList,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.CLIENT, USERS.EMPLOYEE],
        },
      },
      {
        path: "communicate/pushs/",
        component: pushsList,
        meta: {
          scope: [USERS.ADMIN, USERS.EMPLOYEE],
        },
      },
      {
        path: "communicate/pushs/create",
        component: pushsCreate,
        meta: {
          scope: [USERS.ADMIN, USERS.EMPLOYEE],
        },
      },
      {
        path: "communicate/chat/:id",
        component: chatList,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.CLIENT, USERS.EMPLOYEE],
        },
      },
      {
        path: "communicate/organization-news/create",
        component: organizationNewsCreate,
        scope: [USERS.ADMIN, USERS.EMPLOYEE],
      },
      {
        path: "communicate/organization-news/view/:id",
        component: organizationNewsView,
        scope: [USERS.ADMIN, USERS.COLLAB, USERS.CLIENT, USERS.EMPLOYEE],
      },
      {
        path: "communicate/organization-news/",
        component: organizationNewsList,
        scope: [
          USERS.ADMIN,
          USERS.COLLAB,
          USERS.CLIENT,
          USERS.EMPLOYEE,
          USERS.EXTERNAL,
        ],
      },
      {
        path: "communicate/organization-news/update/:id",
        component: organizationNewsUpdate,
        scope: [USERS.ADMIN, USERS.EMPLOYEE],
      },
      {
        path: "accounting-plan/menu",
        component: AccountingMenu,
        scope: [USERS.ADMIN, USERS.COLLAB],
      },
      {
        path: "accounting-plan/menu/view/:id",
        component: AccountingView,
        scope: [USERS.ADMIN, USERS.COLLAB],
      },
      {
        path: "tasks/create",
        component: tasksCreate,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB],
        },
      },
      {
        path: "tasks/:tab",
        component: tasksList,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB],
        },
      },
      {
        path: "tasks/tab-all/:companyId",
        component: tasksList,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB],
        },
      },
      {
        path: "tasks/update/:id",
        component: tasksUpdate,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB],
        },
      },
      {
        path: "tasks/view/:id",
        component: tasksView,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB],
        },
      },
      {
        path: "expense-reports/",
        component: ExpenseReportListCompanies,
        meta: {
          scope: [USERS.COLLAB, USERS.CLIENT, USERS.ADMIN],
        },
      },
      {
        path: "expense-reports/history",
        component: ExpenseReportListCompanies,
        meta: {
          scope: [USERS.COLLAB, USERS.CLIENT, USERS.ADMIN],
        },
      },
      {
        path: "expense-reports/:companyId/view/:id",
        redirect: "expense-reports/:companyId/view/:id/types/notes",
      },
      {
        path: "expense-reports/:companyId/view/:id/types/:types",
        component: ExpenseReportListEmployee,
        meta: {
          scope: [USERS.COLLAB, USERS.CLIENT, USERS.ADMIN],
        },
      },
      {
        path: "expense-reports/:companyId",
        component: ExpenseReportListCompanies,
        meta: {
          scope: [USERS.COLLAB, USERS.CLIENT, USERS.ADMIN],
        },
      },
      {
        path: "news",
        component: ListNews,
        meta: {
          scope: [
            USERS.ADMIN,
            USERS.COLLAB,
            USERS.EXTERNAL,
            USERS.CLIENT,
            USERS.EMPLOYEE,
          ],
        },
      },
      {
        path: "news/:id",
        component: newsView,
        meta: {
          scope: [
            USERS.ADMIN,
            USERS.COLLAB,
            USERS.CLIENT,
            USERS.EMPLOYEE,
            USERS.EXTERNAL,
          ],
        },
      },
      {
        path: "setting",
        component: Setting,
        meta: {
          scope: [USERS.ADMIN, USERS.CLIENT, USERS.COLLAB],
        },
      },
      {
        path: "setting/import",
        component: Import,
        meta: {
          scope: [USERS.ADMIN, USERS.CLIENT],
        },
      },
      {
        path: "setting/companies/",
        component: companiesList,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.EXTERNAL, USERS.CLIENT],
        },
      },
      {
        path: "setting/companies/create",
        component: companiesCreate,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB],
        },
      },
      {
        path: "setting/companies/update/:id",
        component: companiesUpdate,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.CLIENT],
        },
      },
      {
        path: "setting/companies/view/:id",
        component: companiesView,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.EXTERNAL, USERS.CLIENT],
        },
      },
      {
        path: "setting/companies/import",
        component: companiesImport,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.EXTERNAL],
        },
      },
      {
        path: "setting/groupes/",
        component: groupesList,
        meta: {
          scope: [USERS.ADMIN],
        },
      },
      {
        path: "setting/groupes/create",
        component: groupesCreate,
        meta: {
          scope: [USERS.ADMIN],
        },
      },
      {
        path: "setting/groupes/view/:id",
        component: groupesView,
        meta: {
          scope: [USERS.ADMIN],
        },
      },
      {
        path: "setting/groupes/update/:id",
        component: groupesUpdate,
        meta: {
          scope: [USERS.ADMIN],
        },
      },
      {
        path: "setting/users/",
        component: usersListPage,
        name: "user-list",
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.CLIENT],
        },
      },
      {
        path: "setting/users/create",
        component: usersCreate,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.CLIENT],
        },
      },
      {
        path: "setting/users/update/:id",
        name: "user-update",
        component: usersUpdate,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.CLIENT],
        },
      },
      {
        path: "setting/users/view/:id",
        name: "user-detail",
        component: usersView,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.CLIENT],
        },
      },
      {
        path: "setting/users/approve/:id",
        component: usersApprove,
        meta: {
          scope: [USERS.ADMIN],
        },
      },

      {
        path: "setting/users/import",
        component: usersImport,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.EXTERNAL, USERS.CLIENT],
        },
      },
      {
        path: "setting/fiscal-year",
        component: FiscalYear,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.EXTERNAL, USERS.CLIENT],
        },
      },
      {
        path: "sociale/",
        component: Socials,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.CLIENT],
        },
      },
      {
        path: "sociale/fin-de-contract",
        component: FinDeContrat,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.CLIENT],
        },
      },
      {
        path: "sociale/mes-payes",
        component: MesPayes,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.CLIENT],
        },
      },
      {
        path: "sociale/arret",
        component: Arret,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.CLIENT],
        },
      },
      {
        path: "sociale/embauche",
        component: Embauche,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.CLIENT],
        },
      },
      {
        path: "sociale/reprise",
        component: Reprise,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.CLIENT],
        },
      },
      {
        path: "simulateurs/",
        component: Simulateurs,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.CLIENT, USERS.EMPLOYEE],
        },
      },
      {
        path: "simulateurs/",
        component: Simulateurs,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.CLIENT, USERS.EMPLOYEE],
        },
      },
      {
        path: "simulateurs/credit",
        component: Credit,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.CLIENT, USERS.EMPLOYEE],
        },
      },
      {
        path: "simulateurs/frais",
        component: Frais,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.CLIENT, USERS.EMPLOYEE],
        },
      },
      {
        path: "simulateurs/impot-sur",
        component: ImpotSur,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.CLIENT, USERS.EMPLOYEE],
        },
      },
      {
        path: "simulateurs/impot-societe",
        component: ImpotSociete,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.CLIENT, USERS.EMPLOYEE],
        },
      },
      {
        path: "simulateurs/tva",
        component: Tva,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.CLIENT, USERS.EMPLOYEE],
        },
      },
      {
        path: "simulateurs/salary",
        component: Salary,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.CLIENT, USERS.EMPLOYEE],
        },
      },
      {
        path: "simulateurs/marge",
        component: Marge,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.CLIENT, USERS.EMPLOYEE],
        },
      },
      {
        path: "simulateurs/taxe",
        component: Taxe,
        meta: {
          scope: [USERS.ADMIN, USERS.COLLAB, USERS.CLIENT, USERS.EMPLOYEE],
        },
      },
      {
        path: "information",
        component: Information,
      },

      {
        path: "change-password",
        component: usersChangePassword,
      },
    ],
  },
  {
    path: "*",
    redirect: "/dashboard",
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((r) => r.meta.auth)) {
    try {
      await authMiddleware();

      const scopedRoutes = to.matched.filter((r) => r.meta.scope);
      const userScope = store.state.self.scope;
      const userScopeIncluded = scopedRoutes.every((r) =>
        r.meta.scope.includes(userScope)
      );

      if (scopedRoutes.length && userScope && !userScopeIncluded) {
        if (
          from.path === "/" &&
          to.path === "/dashboard/home" &&
          userScope === USERS.SUPERUSER
        ) {
          next("/admin");
        } else {
          next(
            new Error(
              "Vous n'avez pas les droits nécessaires pour accéder à cette page"
            )
          );
        }
      } else {
        next();
      }
    } catch (err) {
      console.log(err);
      store.commit("LOGIN_ERROR");
      next("/signin");
    }
  } else {
    next();
  }
});

export default router;
