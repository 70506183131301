import Vue from "vue";
import Toasted from "vue-toasted";
import VueScrollTo from "vue-scrollto";
import router from "./router";

// toast installation
//https://github.com/shakee93/vue-toasted
//installation d'un plugin à toast (notification sur l'écran)
//création de trois global, une pour l'information
//une pour les erreurs et une la réussite

Vue.use(Toasted, {
  router,
});

// le premier parametre correspond au nom de la global
// le second paramètre à un titre

// POP UP ERREUR

Vue.toasted.register(
  "AppError",
  payload => {
    // if there is no message passed show default message
    if (!payload.message) {
      return "Une erreur est survenue";
    }

    // if there is a message show it with the message
    return payload.message;
  },
  {
    type: "error",
    theme: "toasted-primary",
    position: "top-center",
    className: ["toasted"],
    //durée de la popup
    duration: 5000,
  }
);

// POP UP DE REUSSITE

Vue.toasted.register(
  "AppSucces",
  payload => {
    // if there is no message passed show default message
    if (!payload.message) {
      return "Succes";
    }
    // if there is a message show it with the message
    return payload.message;
  },
  {
    type: "error",
    theme: "toasted-primary",
    position: "top-center",
    className: ["toasted"],
    duration: 5000,
  }
);

// POP UP DINFORMATION

Vue.toasted.register(
  "AppInfo",
  payload => {
    // if there is no message passed show default message
    if (!payload.message) {
      return "Une erreur est survenue";
    }

    // if there is a message show it with the message
    return payload.message;
  },
  {
    theme: "toasted-primary",
    //position: "top-center",
    position: "top-center",
    type: "error",
    duration: 5000,
  }
);

Vue.use(VueScrollTo, {
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});
