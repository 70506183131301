<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.31 25"><path d="M2.742 25a2.719 2.719 0 01-1.886-.754A2.756 2.756 0 010 22.255V2.049A2.051 2.051 0 012.049 0h5.575a.658.658 0 01.465.193l1.894 1.894h10.845a.658.658 0 01.658.658v2.087a.658.658 0 01-1.316 0V3.404H9.71a.658.658 0 01-.465-.193L7.351 1.317h-5.3a.734.734 0 00-.733.733v20.206a1.429 1.429 0 001.425 1.428h.081a2.854 2.854 0 002.2-1.7l.012-.029 6.062-13.893a2.873 2.873 0 012.51-1.748h18.073a1.617 1.617 0 011.367.663 1.678 1.678 0 01.13 1.568l-.01.026-6.333 14.765a2.742 2.742 0 01-2.523 1.663H2.742zm2.729-1.316h18.84a1.427 1.427 0 001.313-.865l6.327-14.751a.413.413 0 00.012-.342.35.35 0 00-.283-.094H13.607a1.541 1.541 0 00-1.285.915l-.012.029-6.062 13.893a3.843 3.843 0 01-.776 1.215z"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconCloudFolder'
  };
</script>
