<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.065 19.956"><g fill="none" stroke="#8adbfc" stroke-width="2"><g><circle cx="8" cy="8" r="8" stroke="none"/><circle cx="8" cy="8" r="7"/></g><path stroke-linecap="round" d="M12.567 13.458l5.084 5.084"/></g></svg>
</template>

<script>
  export default {
    name : 'MeepIconSearchi'
  };
</script>
