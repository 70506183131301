import Vue from "vue";
import store from "./store";
import App from "./App";
import VueSocketIO from "vue-socket.io";
import router from "./router";
import CKEditor from "@ckeditor/ckeditor5-vue";
import vuetify from "@/vuetify";
import "@ckeditor/ckeditor5-build-classic/build/translations/fr";

import config from "./config";

import "./toasted";
// import css
import "./styles/index.scss";

// import global components
import "./components";

// import filters
import "./filters";

// set vee-validate with errors
import "./validation";

// import vue material and set theme
import "./material";

import VueI18n from "vue-i18n";
import { languages } from "@/i18n";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import Vuelidate from "vuelidate";

const messages = Object.assign(languages);

Vue.use(CKEditor);
Vue.use(VueI18n);
Vue.use(Vuelidate);


// Create VueI18n instance with options
export const i18n = new VueI18n({
  locale: "fr", // set locale
  fallbackLocale: "fr",
  messages,
});

Vue.use(
  new VueSocketIO({
    connection: config.apiUrl,
    debug: process.env.NODE_ENV !== "production",
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_",
    },
  })
);

Vue.config.ignoredElements = ["canvas-datagrid"];

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    dsn:
      "https://0b7d7b40a9e44eae85af523519e10440@o585518.ingest.sentry.io/5737715",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.5,
  });
}

/* eslint-disable no-new */
new Vue({
  el: "#app",
  i18n,
  name: "Root",
  router,
  store,
  vuetify,
  render: mount => mount(App),
}).$mount("#app");
