<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.655 32.913"><path d="M24.976 27.636a.679.679 0 00-.679.679v2.564a.677.677 0 01-.677.677H2.035a.677.677 0 01-.678-.677v-21.9l7.62-7.62H23.62a.677.677 0 01.677.677v26.59a.679.679 0 001.357 0V2.036A2.035 2.035 0 0023.619.002H8.696a.679.679 0 00-.48.2L.198 8.22a.679.679 0 00-.2.48v22.182a2.035 2.035 0 002.035 2.035h21.586a2.035 2.035 0 002.035-2.035v-2.564a.679.679 0 00-.678-.682z"/><path d="M8.018 4.068v3.949H4.069a.679.679 0 000 1.357h4.628a.679.679 0 00.679-.679V4.067a.679.679 0 10-1.357 0z"/><g><path d="M19.371 19.138H6.961a.62.62 0 110-1.241h12.41a.62.62 0 110 1.241zM15.987 22.522h-5.641a.62.62 0 110-1.241h5.641a.62.62 0 110 1.241zm-7.9 0H6.959a.62.62 0 110-1.241h1.128a.62.62 0 110 1.241zM15.987 25.906H6.962a.62.62 0 110-1.241h9.025a.62.62 0 110 1.241zM15.986 14.625h-4.513a.62.62 0 110-1.241h4.513a.62.62 0 110 1.241z"/></g></svg>
</template>

<script>
  export default {
    name : 'MeepIconCloudFile'
  };
</script>
