import Vue from "vue";
import Vuex from "vuex";

import state from "./state";
import * as mutations from "./mutations";
import * as actions from "./actions";
import * as getters from "./getters";
import popups from "./modules/popups";
import users from "./modules/users";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  state,
  getters,
  actions,
  mutations,
  modules: {
    popups,
    users,
  },
});
