import { NOTIFICATIONS } from "../constants";
import { themeOptions } from "@/themes";

export const unreadNotifications = state => {
  return state.notifications.filter(
    notif => !notif.is_seen && notif.notification_type !== NOTIFICATIONS.CHAT
  );
};

export const unreadNotificationsChat = state => {
  return state.notifications.filter(
    notif => !notif.is_seen && notif.notification_type === NOTIFICATIONS.CHAT
  );
};

export const logo = state => state.logo;

export const logoMinified = state => state.logoMinified;

export const hasPushs = state =>
  state.self.organization ? state.self.organization.hasPushNotification : false;

export const hasCloud = state =>
  state.self.organization ? state.self.organization.has_cloud : false;

export const self = state => state.self;

export const notifications = state => state.notifications;

export const isAdmin = state => state.self && state.self.scope === 1;

export const isCollab = state => state.self && state.self.scope === 2;

export const isExternal = state => state.self && state.self.scope === 3;

export const isClient = state => state.self && state.self.scope === 4;

export const isEmployee = state => state.self && state.self.scope === 5;

export const isSuperUser = state => state.self && state.self.scope === 6;

export const unreadMessagesCount = state => state.unreadMessagesCount;

export const isMobile = state => state.isMobile;

export const showSnackbar = state => state.showSnackbar;

export const organizationId = state => state.organization_id;

export const canChat = state => state.canChat;

export const isJEPA = state => state.site_name === "Jarige Particuliers";

export const isGC = state => state.site_name === "Groupe Conseils";

export const isLCM = state => state.site_name === "La compta mobile";

export const isCE = state => state.site_name === "Cap Expert";

export const isMEEP = state =>
  state.site_name && state.site_name.toLowerCase().includes("meep");

export const isJEP = state =>
  state.site_name === "Jarige Expertise Professionnels";

export const canAccessLockedFolder = state => {
  if (isAdmin(state) || isCollab(state) || isExternal(state)) {
    return true;
  }
  return false;
};

export const androidUrl = state => state.androidUrl;

export const iOsUrl = state => state.iOsUrl;

export const backgroundUrl = state => {
  const themeId = organizationTheme(state).id;
  let imageName = `bg-${themeId}.png`;

  if (isGC(state)) {
    imageName = "background_gc.png";
  }

  if (isLCM(state)) {
    imageName = "background_lcm.jpg";
  }

  if (isCE(state)) {
    imageName = "background_ce.png";
  }

  if (isJEPA(state)) {
    return "";
  }

  if (isMEEP(state)) {
    imageName = "background_meep.jpg";
  }

  return `url(${require(`../assets/images/${imageName}`)})`;
};

export const organizationTheme = state => {
  const result = themeOptions.find(theme => theme.id === state.color_scheme);

  return result || themeOptions[0];
};

export const newUsers = state => state.newUsers;

export const newCompanies = state => state.newCompanies;

export const importErrorLine = state => state.importErrorLine;
