<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.083 19.066"><defs/><g id="list_accounting_svg__Groupe_112" transform="translate(-529.908 -229.14)"><path id="list_accounting_svg__Tracé_299" d="M537.41 248.666a.492.492 0 01-.185-.036.5.5 0 01-.281-.651l2.592-6.523a.5.5 0 01.466-.317h15.47a.5.5 0 01.408.793l-2.509 3.512a.5.5 0 01-.817-.583l1.942-2.719h-14.153l-2.467 6.206a.5.5 0 01-.466.318z" transform="translate(-3.488 -5.979)"/><path id="list_accounting_svg__Tracé_300" d="M545.318 248.206h-13.9a1.507 1.507 0 01-1.505-1.505v-16.056a1.507 1.507 0 011.505-1.505h7.024a.5.5 0 01.355.147l1.505 1.505a.5.5 0 01.147.355v1a.5.5 0 01-.5.5h-7.024v12.485l3.7-4.765a.5.5 0 01.4-.194h6.268l1.873-1.4a.5.5 0 01.3-.1h8.028a.5.5 0 01.5.5v.5a.5.5 0 01-.136.344l-6.969 7.4a2.26 2.26 0 01-1.571.789zm-13.9-18.062a.5.5 0 00-.5.5V246.7a.5.5 0 00.5.5h13.9a1.274 1.274 0 00.834-.472l6.648-7.054h-7.171l-1.873 1.4a.5.5 0 01-.3.1h-6.19l-3.75 4.824a.5.5 0 01-.4.194h-.7a.5.5 0 01-.5-.5V232.15a.5.5 0 01.5-.5h7.024v-.294l-1.211-1.211z"/><path id="list_accounting_svg__Tracé_301" d="M557.437 238.656a.5.5 0 01-.461-.7l1.205-2.813h-8.771a.5.5 0 110-1h9.533a.5.5 0 01.461.7l-1.505 3.512a.5.5 0 01-.462.301z" transform="translate(-9.467 -2.491)"/></g></svg>
</template>

<script>
  export default {
    name : 'MeepIconListAccounting'
  };
</script>
