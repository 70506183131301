<template>
  <!-- eslint-disable -->
  <svg
    id="e1faa4ad-e76d-420a-b99a-2e9827430828"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 34.14 21.07"
  >
    <title>tax</title>
    <g id="a556cf43-0ab8-443f-9026-21c4420ebf63" data-name="Groupe 33">
      <path
        id="bd5f44db-82ca-4900-a2c9-e03148d9ffdc"
        data-name="Tracé 106"
        d="M33.41,0H.73A.73.73,0,0,0,0,.73H0V20.34a.73.73,0,0,0,.73.73H33.41a.73.73,0,0,0,.73-.73V.73A.73.73,0,0,0,33.41,0ZM1.46,1.82a.36.36,0,0,1,.36-.36H5a.34.34,0,0,1,.28.14.38.38,0,0,1,.07.31A4.72,4.72,0,0,1,1.91,5.29H1.82A.35.35,0,0,1,1.46,5h0ZM5.23,19.46A.35.35,0,0,1,5,19.6H1.82a.36.36,0,0,1-.36-.35V16.12a.36.36,0,0,1,.36-.36h.09A4.73,4.73,0,0,1,5.3,19.16a.39.39,0,0,1-.07.31ZM13,19.33a.36.36,0,0,1-.35.27H7.17a.34.34,0,0,1-.35-.3,6.17,6.17,0,0,0-5.06-5.05.36.36,0,0,1-.3-.35V7.16a.36.36,0,0,1,.3-.35,6.18,6.18,0,0,0,5.06-5,.34.34,0,0,1,.35-.3H12.6a.35.35,0,0,1,.16.67,9.43,9.43,0,0,0,0,16.79.37.37,0,0,1,.19.41Zm-3.86-8.8a8,8,0,1,1,8,8h0a8,8,0,0,1-8-8Zm23.59,8.71a.36.36,0,0,1-.36.36H29.19a.36.36,0,0,1-.36-.35s0-.06,0-.1a4.72,4.72,0,0,1,3.39-3.38h.09a.35.35,0,0,1,.36.35h0Zm0-5.34a.36.36,0,0,1-.3.35,6.18,6.18,0,0,0-5.06,5.05.35.35,0,0,1-.35.3H21.54a.36.36,0,0,1-.16-.67,9.43,9.43,0,0,0,0-16.79.36.36,0,0,1-.16-.49.38.38,0,0,1,.32-.19H27a.35.35,0,0,1,.35.3,6.18,6.18,0,0,0,5.06,5.06.35.35,0,0,1,.3.35Zm0-8.95a.35.35,0,0,1-.14.28.34.34,0,0,1-.22.07h-.09a4.72,4.72,0,0,1-3.39-3.38.37.37,0,0,1,.26-.44h3.22a.36.36,0,0,1,.36.36h0Z"
        style="fill: #f4c82e;"
      />
      <path
        id="a9f5e918-07f5-4f85-a5a6-db70e0c18597"
        data-name="Tracé 111"
        d="M17.34,9.85c2.3.9,2.64,2.24,2.64,3a2.71,2.71,0,0,1-.92,2,2.93,2.93,0,0,1-1,.6.37.37,0,0,0-.24.34V16a.73.73,0,0,1-1.46,0v-.15a.37.37,0,0,0-.25-.35,2.9,2.9,0,0,1-1.88-1.72.74.74,0,0,1,.42-.95.75.75,0,0,1,.95.42h0a1.64,1.64,0,0,0,2.21.71,1.81,1.81,0,0,0,.29-.19,1.29,1.29,0,0,0,.43-1c0-.79-1.07-1.33-1.71-1.58-2.3-.9-2.64-2.24-2.64-3a2.71,2.71,0,0,1,.92-2,2.84,2.84,0,0,1,1-.6.36.36,0,0,0,.24-.34v-.2a.73.73,0,0,1,.75-.71.74.74,0,0,1,.71.71v.15a.37.37,0,0,0,.25.35,2.9,2.9,0,0,1,1.88,1.72.75.75,0,0,1-.42,1h0a.72.72,0,0,1-.94-.43,1.64,1.64,0,0,0-2.2-.71,1.53,1.53,0,0,0-.3.19,1.26,1.26,0,0,0-.44,1c0,.79,1.08,1.33,1.72,1.58Z"
        style="fill: #fff;"
      />
      <path
        id="b1a6d8fc-b53e-4aba-8840-afca3e75056c"
        data-name="Tracé 112"
        d="M20,12.8a2.71,2.71,0,0,1-.92,2,2.93,2.93,0,0,1-1,.6.37.37,0,0,0-.24.34V16a.73.73,0,0,1-1.46,0v-.15a.35.35,0,0,0-.25-.34,2.94,2.94,0,0,1-1.88-1.73.74.74,0,0,1,.42-.95.75.75,0,0,1,.95.42h0a1.63,1.63,0,0,0,2.21.71,1.32,1.32,0,0,0,.29-.19,1.29,1.29,0,0,0,.43-1c0-.79-1.07-1.33-1.71-1.58-2.3-.9-2.64-2.23-2.64-3a2.71,2.71,0,0,1,.92-2,3,3,0,0,1,1-.6.36.36,0,0,0,.24-.34v-.2a.73.73,0,0,1,.75-.71.74.74,0,0,1,.71.71v.15a.37.37,0,0,0,.25.35,2.92,2.92,0,0,1,1.88,1.73.74.74,0,0,1-.42.94h0a.85.85,0,0,1-.26.05.73.73,0,0,1-.69-.48,1.64,1.64,0,0,0-2.2-.71,1.53,1.53,0,0,0-.3.19,1.26,1.26,0,0,0-.44,1c0,.79,1.08,1.33,1.72,1.58,2.3.91,2.64,2.24,2.64,3Z"
        style="fill: #f4c82e;"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "JEPAIconTax",
};
</script>
