import { adaptUser } from "@/services/util";

const mutations = {
  changeList: (state, { list, items }) => {
    items = items.map(adaptUser);
    state[list] = items;
  },
  deleteItem: (state, { list, item }) => {
    state[list] = state[list].filter((_user) => item.id !== _user.id);
  },
  addItem: (state, { list, item }) => {
    state[list] = [...state[list], item];
  },
};

export default mutations;
