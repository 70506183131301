<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26"><g fill="none" stroke="#000" stroke-width="1.5"><circle cx="13" cy="13" r="13" stroke="none"/><circle cx="13" cy="13" r="12.25"/></g><path d="M7.07 15.359a2.478 2.478 0 110-4.956.652.652 0 110 1.3 1.175 1.175 0 101.175 1.175.651.651 0 111.3 0 2.481 2.481 0 01-2.475 2.481zM12.822 15.359a2.478 2.478 0 010-4.956.652.652 0 010 1.3 1.175 1.175 0 101.175 1.175.651.651 0 111.3 0 2.481 2.481 0 01-2.475 2.481zM18.831 15.359a2.478 2.478 0 010-4.956.652.652 0 110 1.3 1.175 1.175 0 101.175 1.175.651.651 0 111.3 0 2.481 2.481 0 01-2.475 2.481z"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconCloudEllipsis'
  };
</script>
