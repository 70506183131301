<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.98 36.765"><path d="M38.477 6.366H28.445v-3.86a2.509 2.509 0 00-2.507-2.507h-10.89a2.509 2.509 0 00-2.506 2.507v3.86H2.51A2.509 2.509 0 00.004 8.873v25.385a2.509 2.509 0 002.506 2.507h35.967a2.509 2.509 0 002.507-2.507V8.873a2.51 2.51 0 00-2.507-2.507zm-24.265-3.86a.837.837 0 01.836-.835h10.89a.836.836 0 01.835.835v3.86H14.211zm-11.7 5.531h35.965a.836.836 0 01.836.836v9.638a77.938 77.938 0 01-14.884 2.175V16.93a.835.835 0 00-.835-.835h-6.2a.836.836 0 00-.837.835v3.76a77.742 77.742 0 01-14.883-2.177V8.877a.837.837 0 01.832-.839zm20.248 9.728v6.272a2.264 2.264 0 11-4.528 0v-6.272zM38.477 35.09H2.51a.837.837 0 01-.836-.835V20.229a79.473 79.473 0 0014.883 2.131v1.678a3.936 3.936 0 007.872 0V22.36a79.64 79.64 0 0014.884-2.13v14.028a.836.836 0 01-.836.832z"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconSettingCompanies'
  };
</script>
