import Vue from "vue";

/* Import custom components */
import dashMenu from "./components/dash-menu.vue";
import modal from "./components/modal.vue";
import searchBar from "./components/search-bar.vue";
import actionBar from "./components/action-bar.vue";
import sortableList from "./components/sortable-list.vue";
import popups from "./components/popups.vue";
import loader from "./components/loader.vue";
import footer from "./components/footer.vue";

Vue.component("Modal", modal);
Vue.component("DashMenu", dashMenu);
Vue.component("SearchBar", searchBar);
Vue.component("ActionBar", actionBar);
Vue.component("SortableList", sortableList);
Vue.component("Popups", popups);
Vue.component("Loader", loader);
Vue.component("Foot", footer);
