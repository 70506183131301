<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.771 34.721"><path d="M24.969 3.732h-6.01v-.515a3.337 3.337 0 00-3.438-3.218H12.25a3.337 3.337 0 00-3.438 3.218v.515H2.8a2.911 2.911 0 00-2.8 3v1.24a1.212 1.212 0 001.212 1.212H26.56a1.212 1.212 0 001.212-1.212v-1.24a2.911 2.911 0 00-2.803-3zm-14.621-.515a1.847 1.847 0 011.9-1.781h3.271a1.847 1.847 0 011.9 1.781v.515h-7.071zm15.081 4.335H2.342a.819.819 0 01-.818-.818A1.328 1.328 0 012.8 5.37h22.169a1.232 1.232 0 01.9.4 1.416 1.416 0 01.374.968.818.818 0 01-.814.815zM13.571 12.533a1.592 1.592 0 00-1.533 1.642V28.09a1.592 1.592 0 001.533 1.642h.63a1.592 1.592 0 001.532-1.642V14.175a1.592 1.592 0 00-1.532-1.642zm.682 1.642V28.09a.054.054 0 01-.052.055h-.63a.054.054 0 01-.052-.055V14.175a.054.054 0 01.052-.055h.63a.054.054 0 01.052.055zM8.2 12.533h-.627a1.491 1.491 0 00-1.121.522 1.721 1.721 0 00-.408 1.235l.908 13.915a1.587 1.587 0 001.529 1.527h.627a1.494 1.494 0 001.121-.522 1.723 1.723 0 00.407-1.235L9.728 14.06A1.587 1.587 0 008.2 12.533zm.946 15.595a.051.051 0 01-.039.018H8.48a.053.053 0 01-.052-.052L7.52 14.18a.058.058 0 01.014-.042.05.05 0 01.038-.018h.627a.054.054 0 01.052.052l.908 13.914a.058.058 0 01-.012.042z"/><path d="M25.266 9.782a.741.741 0 00-.738.677l-1.842 21.21a1.543 1.543 0 01-1.5 1.464H6.58a1.543 1.543 0 01-1.5-1.464L3.241 10.458a.741.741 0 00-.738-.677.741.741 0 00-.738.8l1.84 21.23a3.06 3.06 0 002.973 2.9h14.613a3.06 3.06 0 002.973-2.9l1.84-21.23a.741.741 0 00-.738-.8z"/><path d="M19.571 12.533a1.587 1.587 0 00-1.529 1.528l-.908 13.915a1.722 1.722 0 00.408 1.236 1.492 1.492 0 001.12.521h.627a1.586 1.586 0 001.528-1.528l.908-13.916a1.721 1.721 0 00-.408-1.234 1.491 1.491 0 00-1.121-.522zm.678 1.646l-.908 13.915a.054.054 0 01-.051.052h-.627a.05.05 0 01-.038-.017.056.056 0 01-.014-.041l.908-13.915a.054.054 0 01.052-.052h.627a.05.05 0 01.038.018.057.057 0 01.013.04z"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconDelete'
  };
</script>
