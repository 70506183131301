"use strict";

module.exports = {
  //? All USERS SCOPE
  USERS: {
    ADMIN: 1,
    COLLAB: 2,
    EXTERNAL: 3,
    CLIENT: 4,
    EMPLOYEE: 5,
    SUPERUSER: 6,
  },

  //? All NOTIFICATIONS
  NOTIFICATIONS: {
    COMPANY_CREATED: 1 /* Informer admin que collab a créé entreprise */,
    COMPANY_REMOVED: 2 /* Informer admin que collab a supprimé entreprise */,
    COMPANY_UPDATED: 3 /* Informer admin que collab a modifié entreprise */,
    COMPANY_USER_ADDED: 4 /* Informer un utilisateur qu'il a été ajouté dans une entreprise */,
    COMPANY_USER_REMOVED: 5 /* Informer un utilisateur qu'il a été retiré d'une entreprise */,
    COMPANY_USER_RIGHTS_UPDATED: 8 /* Informer un utilisateur que ses droits ont été modifiés dans une entreprise */,
    FILE_ADDED: 6 /* Informer que quelqu'un a placé un fichier dans le cloud */,
    FILE_REMOVED: 7 /* Informer client ou externe que admin ou collab a supprimé des fichiers dans dépot client ou externe */,
    CHAT: 9 /* Informer que quelqu'un a envoyé un message */,
    PUSH_NOTIFICATION: 10 /* Informer que quelqu'un a envoyé un Push */,
    NEWS: 11 /* Informer qu'il y une nouvelles */,
  },

  //? ALL FOLDERS NAME
  FOLDERS: {
    CLIENT: "Mes dépôts",
    COLLAB: "Cabinet",
    EXTERNAL: "Mes documents",

    DSN: "Gestion RH",
    BANK: "Relevés bancaires",
    PERSONEL_MANAGEMENT: "Gestion du personnel",
    NOTES: "Notes de frais",
    SOCIAL: "Social",
    VARIOUS: "Divers",
    SELL_RECEIPT: "Factures de vente",
    BUY_RECEIPT: `Factures d'achats`,

    ETAT_CIVIL: "01 Paramétrage de l’État Civil",
    REVENUS: "02 Revenus",
    REVENUS_FONCIERS: "03 Revenus fonciers",
    PRO_NON_SALARIE: "04 Professions non salariées",
    REDUCTION_CREDIT_IMPOT: "05 Réductions et Crédits d’impôts",
    ESPACE_IMPOT: "06 Mon espace impôt.gouv",
  },

  //? ALL CHAT COLORS
  CHAT_COLORS: [
    "#f9cdd5",
    "#fea3ae",
    "#ff7d93",
    "#fc5e80",
    "#fc2d64",
    "#e20067",
    "#c10865",
    "#a51664",
    "#890a55",
    "#e5b5d4",
    "#e89cc9",
    "#e07cc1",
    "#dd54b9",
    "#d82eb4",
    "#bc04a2",
    "#930586",
    "#6d0668",
    "#591059",
    "#cdb2ed",
    "#c295ee",
    "#a66cea",
    "#8642e5",
    "#681be0",
    "#4812cc",
    "#3000a5",
    "#160170",
    "#0c0249",
    "#c5c5f9",
    "#a09efe",
    "#7c7cfc",
    "#5a5af9",
    "#3737f7",
    "#1919f2",
    "#0000dd",
    "#0000b2",
    "#000082",
    "#b9dcf9",
    "#8dc5fd",
    "#64b6f9",
    "#3daaf4",
    "#3a9fd6",
    "#3189af",
    "#29728c",
    "#1f5968",
    "#174049",
    "#aee4f4",
    "#8adbfc",
    "#62d6f9",
    "#2fd2ed",
    "#00d7e2",
    "#00bbce",
    "#00a2aa",
    "#007c7c",
    "#00514f",
  ],

  //? ALL MOTIF OF CONTRAcT
  motifContrat: [
    {
      text: "Licenciement suite à une liquidation judiciaire",
      value: "011 - licenciement suite à une liquidation judiciaire",
    },
    {
      text: "Licenciement suite à une fermeture définitive de l’établissement",
      value:
        "012 - licenciement suite à une fermeture définitive de l’établissement",
    },
    {
      text: "Licenciement pour motif économique",
      value: "014 - licenciement pour motif économique",
    },
    {
      text: "Licenciement pour fin de chantier",
      value: "015 - licenciement pour fin de chantier",
    },
    {
      text: "Licenciement pour autre motif",
      value: "020 - licenciement pour autre motif",
    },
    {
      text: "Licenciement convention CATS",
      value: "086 - licenciement convention CATS",
    },
    {
      text: "Licenciement pour faute grave",
      value: "087 - licenciement pour faute grave",
    },
    {
      text: "Licenciement pour faute lourde",
      value: "088 - licenciement pour faute lourde",
    },
    {
      text: "Licenciement pour force majeure",
      value: "089 - licenciement pour force majeure",
    },
    {
      text:
        "Licenciement pour inaptitude physique d'origine non professionnelle",
      value:
        "091 - licenciement pour inaptitude physique d'origine non professionnelle",
    },
    {
      text: "Licenciement pour inaptitude physique d'origine professionnelle",
      value:
        "092 - licenciement pour inaptitude physique d'origine professionnelle",
    },
    {
      text: "Licenciement suite à décision d'une autorité administrative",
      value:
        "093 - licenciement suite à décision d'une autorité administrative",
    },
    {
      text: "Fin de CDD ou fin d'accueil occasionnel",
      value: "031 - fin de CDD ou fin d'accueil occasionnel",
    },
    {
      text: "Fin de mission d'intérim",
      value: "032 - fin de mission d'intérim",
    },
    {
      text: "Fin de période d'essai à l'initiative de l'employeur",
      value: "034 - fin de période d'essai à l'initiative de l'employeur",
    },
    {
      text: "Fin de période d'essai à l'initiative du salarié",
      value: "035 - fin de période d'essai à l'initiative du salarié",
    },
    {
      text:
        "Rupture anticipée d’un CDD ou d’un contrat de mission en cas d’inaptitude physique constatée par le médecin du travail",
      value:
        "033 - rupture anticipée d’un CDD ou d’un contrat de mission en cas d’inaptitude physique constatée par le médecin du travail",
    },
    {
      text:
        "Rupture anticipée d'un CDD, d'un contrat d'apprentissage ou d’un contrat de mission à l'initiative de l'employeur",
      value:
        "036 - rupture anticipée d'un CDD, d'un contrat d'apprentissage ou d’un contrat de mission à l'initiative de l'employeur",
    },
    {
      text:
        "Rupture anticipée d'un CDD, d'un contrat d'apprentissage ou d’un contrat de mission à l'initiative du salarié",
      value:
        "037 - rupture anticipée d'un CDD, d'un contrat d'apprentissage ou d’un contrat de mission à l'initiative du salarié",
    },
    {
      text:
        "Rupture pour motif économique dans le cadre d’un contrat de sécurisation professionnelle CSP",
      value:
        "026 - rupture pour motif économique dans le cadre d’un contrat de sécurisation professionnelle CSP",
    },
    { text: "Rupture conventionnelle", value: "043 - rupture conventionnelle" },
    {
      text:
        "Rupture de contrat de travail ou d’un contrat de mission pour force majeure ou fait du prince",
      value:
        "083 - rupture de contrat de travail ou d’un contrat de mission pour force majeure ou fait du prince",
    },
    {
      text:
        "Rupture d'un commun accord du CDD, du contrat d'apprentissage ou d’un contrat de mission",
      value:
        "084 - rupture d'un commun accord du CDD, du contrat d'apprentissage ou d’un contrat de mission",
    },
    {
      text: "Rupture anticipée du contrat de travail pour arrêt de tournage",
      value:
        "094 - rupture anticipée du contrat de travail pour arrêt de tournage",
    },
    {
      text:
        "Rupture anticipée du contrat de travail ou d’un contrat de mission pour faute grave",
      value:
        "095 - rupture anticipée du contrat de travail ou d’un contrat de mission pour faute grave",
    },
    {
      text:
        "Rupture anticipée du contrat de travail ou d’un contrat de mission pour faute lourde",
      value:
        "096 - rupture anticipée du contrat de travail ou d’un contrat de mission pour faute lourde",
    },
    {
      text:
        "Rupture anticipée d’un contrat de travail ou d’un contrat de mission suite à fermeture de l'établissement",
      value:
        "097 - rupture anticipée d’un contrat de travail ou d’un contrat de mission suite à fermeture de l'établissement",
    },
    {
      text:
        "Décès de l'employeur ou internement / conduit à un licenciement autre motif",
      value:
        "065 - décès de l'employeur ou internement / conduit à un licenciement autre motif",
    },
    {
      text: "Décès du salarié / rupture force majeure",
      value: "066 - décès du salarié / rupture force majeure",
    },
    {
      text: "Fin de contrat d'apprentissage",
      value: "081 - fin de contrat d'apprentissage",
    },
    {
      text:
        "Fin de relation avec l’employeur (autre que contrat de travail, convention ou mandat)",
      value:
        "999 - fin de relation avec l’employeur (autre que contrat de travail, convention ou mandat)",
    },
    { text: "Fin de mandat", value: "085 - fin de mandat" },
    {
      text: "Mise à la retraite par l'employeur",
      value: "038 - mise à la retraite par l'employeur",
    },
    {
      text: "Départ à la retraite à l'initiative du salarié",
      value: "039 - départ à la retraite à l'initiative du salarié",
    },
    {
      text: "Prise d'acte de la rupture de contrat de travail",
      value: "058 - prise d'acte de la rupture de contrat de travail",
    },
    { text: "Démission", value: "059 - démission" },
    {
      text: "Résiliation judiciaire du contrat de travail",
      value: "082 - résiliation judiciaire du contrat de travail",
    },
    { text: "Retrait d'enfant", value: "098 - retrait d'enfant" },
    {
      text:
        "Transfert du contrat de travail sans rupture du contrat vers un autre établissement n'effectuant pas encore de DSN",
      value:
        "998 - transfert du contrat de travail sans rupture du contrat vers un autre établissement n'effectuant pas encore de DSN",
    },
    {
      text: "Autre fin de contrat pour motif économique",
      value: "025 - autre fin de contrat pour motif économique",
    },
  ],

  //? ALL SOCIALS TYPE

  SOCIALS: {
    ARRET: "arret",
    EMBAUCHE: "embauche",
    FIN: "fin",
    PAYE: "paye",
    REPRISE: "reprise",
  },

  //? ALL MODAL CONTEXT

  MODALS: {
    TRASH: "trash",
    RESTORE: "restore",
    DELETE: "delete",
    SAVE: "save",
    UNSAVE: "unsave",
  },

  //? ALL VEHICLE TYPE
  vehicleTypes: [
    { text: "Automobile", value: "auto" },
    { text: "Moto (> 50cc)", value: "moto" },
    { text: "Cyclomoteur (< 50cc)", value: "cyclo" },
  ],

  //? ALL VEHICLE POWER
  vehiclePowers: [
    { text: "1 CV", value: 1 },
    { text: "2 CV", value: 2 },
    { text: "3 CV", value: 3 },
    { text: "4 CV", value: 4 },
    { text: "5 CV", value: 5 },
    { text: "6 CV", value: 6 },
    { text: "7 CV ou plus", value: 7 },
  ],

  //? ALL TAUX

  tauxs: [
    { text: "20%", value: 120 },
    { text: "10%", value: 110 },
    { text: "8,5%", value: 108.5 },
    { text: "5,5%", value: 105.5 },
    { text: "2,1%", value: 102.1 },
    { text: "1,05%", value: 101.05 },
  ],
};
