import fetch from "../services/fetch";
import store from "../store";
import files from "@/model/files";
import { FOLDERS } from "@/constants";

export default {
  get,
  getAll,
  getTrashed,
  create,
  update,
  putInTrash,
  restore,
  remove,
  getCompanyByExpenseReportsStatus,
  getLinkToNoteFolder,
};

function get(id) {
  return fetch("api/companies/" + id, {
    method: "get",
    headers: { Authorization: store.state.token },
  });
}

function getAll(params) {
  let queryParams = "";

  if (params) {
    const sortType = params.type || "desc";
    queryParams += `?sort=${params.sort}&sortType=${sortType}`;
  }

  return fetch("api/companies" + queryParams, {
    method: "get",
    headers: { Authorization: store.state.token },
  });
}

function getCompanyByExpenseReportsStatus() {
  return fetch("api/companies/get-expense", {
    method: "get",
    headers: { Authorization: store.state.token },
  });
}

function getTrashed() {
  return fetch("api/companies/get-trashed", {
    method: "post",
    headers: {
      Authorization: store.state.token,
    },
  });
}

function create(company) {
  return fetch("api/companies", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(company),
  });
}

function update(company) {
  return fetch("api/companies/" + company.id, {
    method: "put",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(company),
  });
}

/* Met le flag "is_trashed" de l'entreprise à 1 */
function putInTrash(id) {
  return fetch("api/companies/put-in-trash", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id: id }),
  });
}

function restore(id) {
  return fetch("api/companies/restore", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id: id }),
  });
}

function remove(id) {
  return fetch("api/companies/" + id, {
    method: "delete",
    headers: { Authorization: store.state.token },
  });
}

async function getLinkToNoteFolder(companyId) {
  const folder = await files.getFolderBySearch({
    companyId: companyId,
    folderName: FOLDERS.NOTES,
  });

  if (folder) {
    return `/dashboard/cloud/${folder.id}`;
  }

  return ``;
}
