export const themeOptions = [
  {
    "--primary": "#dc333e",
    "--bg-primary": "#dc333e",
    "--bg-primary-blur": "#dc333e7a",
    "--bg-secondary": "#dc333e",
    "--text-alternate": "#eeeeee",
    "--text-primary": "#fff",
    "--text-secondary": "#979797",
    "--md-theme-default-text-primary-on-accent": "#fff",
    "--md-theme-default-primary-on-background": "#dc333e",
    "--md-theme-default-primary": "#dc333e",
    "--md-theme-default-accent": "#dc333e",
    "--bg-alternate": "#dc333e",
    name: "Rouge",
    id: 0,
  },
  {
    "--primary": "#415c97",
    "--bg-primary": "#415c97",
    "--bg-primary-blur": "#415c977a",
    "--bg-secondary": "#415c97",
    "--text-alternate": "#eeeeee",
    "--text-primary": "#fff",
    "--text-secondary": "#979797",
    "--md-theme-default-primary-on-background": "#415c97",
    "--md-theme-default-primary": "#415c97",
    "--md-theme-default-accent": "#415c97",
    "--bg-alternate": "#415c97",
    name: "Bleu Foncé",
    id: 1,
  },
  {
    "--primary": "#87d37c",
    "--bg-primary": "#87d37c",
    "--bg-primary-blur": "#87d37c7a",
    "--bg-secondary": "#87d37c",
    "--text-alternate": "#eeeeee",
    "--text-primary": "#fff",
    "--text-secondary": "#979797",
    "--md-theme-default-primary-on-background": "#87d37c",
    "--md-theme-default-primary": "#87d37c",
    "--md-theme-default-accent": "#87d37c",
    "--bg-alternate": "#87d37c",
    name: "Vert Clair",
    id: 2,
  },
  {
    "--primary": "#3b6b45",
    "--bg-primary": "#3b6b45",
    "--bg-primary-blur": "#3b6b457a",
    "--bg-secondary": "#3b6b45",
    "--text-alternate": "#eeeeee",
    "--text-primary": "#fff",
    "--text-secondary": "#979797",
    "--md-theme-default-primary-on-background": "#3b6b45",
    "--md-theme-default-primary": "#3b6b45",
    "--md-theme-default-accent": "#3b6b45",
    "--bg-alternate": "#3b6b45",
    name: "Vert Foncé",
    id: 3,
  },
  {
    "--primary": "#7F43FE",
    "--secondary": "#000",
    "--bg-primary": "#8552fd",
    "--bg-primary-blur": "#8552fd7a",
    "--bg-secondary": "#a880ff",
    "--text-alternate": "#eeeeee",
    "--text-primary": "#fff",
    "--text-secondary": "#979797",
    "--text-inverted": "#000",
    "--md-table-background": "#fff",
    "--bg-third": "#000",
    "--md-theme-default-primary-on-background": "#000",
    "--md-theme-default-primary": "#7F43FE",
    "--md-theme-default-accent": "#7F43FE",
    "--bg-alternate": "#7F43FE",
    "--font": "Poppins-Regular",
    "--font-bold": "Poppins-Bold",
    "--font-extrabold": "Poppins-Bold",
    "--font-x": "Poppins-Light",
    "--font-medium": "Poppins-Medium",
    "--font-semibold": "Poppins-SemiBold",
    "--font-black": "Poppins-Black",
    name: "Violet",
    id: 4,
  },
  {
    "--primary": "#f39c12",
    "--bg-primary": "#f39c12",
    "--bg-primary-blur": "#f39c127a",
    "--bg-secondary": "#f39c12",
    "--text-alternate": "#eeeeee",
    "--text-primary": "#eeeeee",
    "--text-secondary": "#979797",
    "--md-theme-default-primary-on-background": "#f39c12",
    "--md-theme-default-primary": "#f39c12",
    "--md-theme-default-accent": "#f39c12",
    "--bg-alternate": "#f39c12",
    name: "Orange",
    id: 5,
  },
  {
    "--primary": "#f4d03f",
    "--bg-primary": "#f4d03f",
    "--bg-primary-blur": "#f4d03f7a",
    "--bg-secondary": "#f4d03f",
    "--text-alternate": "#eeeeee",
    "--text-primary": "#eeeeee",
    "--text-secondary": "#979797",
    "--md-theme-default-primary-on-background": "#f4d03f",
    "--md-theme-default-primary": "#f4d03f",
    "--md-theme-default-accent": "#f4d03f",
    "--bg-alternate": "#f4d03f",
    name: "Jaune",
    id: 6,
  },
  {
    "--primary": "#59abe3",
    "--bg-primary": "#59abe3",
    "--bg-primary-blur": "#59abe37a",
    "--bg-secondary": "#59abe3",
    "--text-alternate": "#eeeeee",
    "--text-primary": "#eeeeee",
    "--text-secondary": "#979797",
    "--md-theme-default-primary-on-background": "#59abe3",
    "--md-theme-default-primary": "#59abe3",
    "--md-theme-default-accent": "#59abe3",
    "--bg-alternate": "#59abe3",
    "--font": "Dosis semibold",
    "--font-bold": "Dosis bold",
    name: "Bleu Clair",
    id: 7,
  },
  {
    "--primary": "#ff94c7",
    "--bg-primary": "#ff94c7",
    "--bg-primary-blur": "#ff94c77a",
    "--bg-secondary": "#ff94c7",
    "--text-alternate": "#eeeeee",
    "--text-primary": "#eeeeee",
    "--text-secondary": "#979797",
    "--md-theme-default-primary-on-background": "#ff94c7",
    "--md-theme-default-primary": "#ff94c7",
    "--md-theme-default-accent": "#ff94c7",
    "--bg-alternate": "#ff94c7",
    name: "Rose",
    id: 8,
  },
  {
    "--primary": "#36d7b7",
    "--bg-primary": "#36d7b7",
    "--bg-primary-blur": "#36d7b77a",
    "--bg-secondary": "#36d7b7",
    "--text-alternate": "#eeeeee",
    "--text-primary": "#eeeeee",
    "--text-secondary": "#979797",
    "--md-theme-default-primary-on-background": "#36d7b7",
    "--md-theme-default-primary": "#36d7b7",
    "--md-theme-default-accent": "#36d7b7",
    "--bg-alternate": "#36d7b7",
    name: "Turquoise",
    id: 9,
  },
  {
    "--primary": "#cdbca6",
    "--bg-primary": "#cdbca6",
    "--bg-primary-blur": "#cdbca67a",
    "--bg-secondary": "#cdbca6",
    "--text-alternate": "#eeeeee",
    "--text-primary": "#eeeeee",
    "--text-secondary": "#979797",
    "--md-theme-default-primary-on-background": "#cdbca6",
    "--md-theme-default-primary": "#cdbca6",
    "--md-theme-default-accent": "#cdbca6",
    "--bg-alternate": "#cdbca6",
    name: "Beige",
    id: 10,
  },
  {
    "--primary": "#6c7a89",
    "--bg-primary": "#6c7a89",
    "--bg-primary-blur": "#6c7a897a",
    "--bg-secondary": "#6c7a89",
    "--text-alternate": "#eeeeee",
    "--text-primary": "#eeeeee",
    "--text-secondary": "#979797",
    "--md-theme-default-primary-on-background": "#6c7a89",
    "--md-theme-default-primary": "#6c7a89",
    "--md-theme-default-accent": "#6c7a89",
    "--bg-alternate": "#6c7a89",
    name: "Gris",
    id: 11,
  },
  {
    "--primary": "#96281b",
    "--bg-primary": "#96281b",
    "--bg-primary-blur": "#96281b7a",
    "--bg-secondary": "#96281b",
    "--text-alternate": "#eeeeee",
    "--text-primary": "#eeeeee",
    "--text-secondary": "#979797",
    "--md-theme-default-primary-on-background": "#96281b",
    "--md-theme-default-primary": "#96281b",
    "--md-theme-default-accent": "#96281b",
    "--bg-alternate": "#96281b",
    name: "Rouge foncé",
    id: 12,
  },
  {
    "--primary": "#db0a5b",
    "--bg-primary": "#db0a5b",
    "--bg-primary-blur": "#db0a5b7a",
    "--bg-secondary": "#db0a5b",
    "--text-alternate": "#eeeeee",
    "--text-primary": "#eeeeee",
    "--text-secondary": "#979797",
    "--md-theme-default-primary-on-background": "#db0a5b",
    "--md-theme-default-primary": "#db0a5b",
    "--md-theme-default-accent": "#db0a5b",
    "--bg-alternate": "#db0a5b",
    name: "Magenta",
    id: 13,
  },
  {
    "--primary": "#2e3131",
    "--bg-primary": "#2e3131",
    "--bg-primary-blur": "#2e31317a",
    "--bg-secondary": "#2e3131",
    "--text-alternate": "#eeeeee",
    "--text-primary": "#eeeeee",
    "--text-secondary": "#979797",
    "--md-theme-default-primary-on-background": "#2e3131",
    "--md-theme-default-primary": "#2e3131",
    "--md-theme-default-accent": "#2e3131",
    "--bg-alternate": "#2e3131",
    name: "Noir",
    id: 14,
  },
  {
    "--primary": "#f1a9a0",
    "--bg-primary": "#f1a9a0",
    "--bg-primary-blur": "#f1a9a07a",
    "--bg-secondary": "#f1a9a0",
    "--text-alternate": "#eeeeee",
    "--text-primary": "#eeeeee",
    "--text-secondary": "#979797",
    "--md-theme-default-primary-on-background": "#f1a9a0",
    "--md-theme-default-primary": "#f1a9a0",
    "--md-theme-default-accent": "#f1a9a0",
    "--bg-alternate": "#f1a9a0",
    name: "Nude",
    id: 15,
  },
  {
    "--primary": "#979797",
    "--bg-primary": "#f2f1ef",
    "--bg-primary-blur": "#f2f1ef7a",
    "--bg-secondary": "#f2f1ef",
    "--text-alternate": "#eeeeee",
    "--text-primary": "#eeeeee",
    "--text-secondary": "#979797",
    "--md-theme-default-primary-on-background": "#f2f1ef",
    "--md-theme-default-primary": "#f2f1ef",
    "--md-theme-default-accent": "#f2f1ef",
    "--md-theme-default-text-primary-on-primary": "#979797",

    "--bg-alternate": "#f2f1ef",
    name: "Blanc",
    id: 16,
  },
  {
    "--primary": "#825a2c",
    "--bg-primary": "#825a2c",
    "--bg-primary-blur": "#825a2c7a",
    "--bg-secondary": "#825a2c",
    "--text-alternate": "#eeeeee",
    "--text-primary": "#eeeeee",
    "--text-secondary": "#979797",
    "--md-theme-default-primary-on-background": "#825a2c",
    "--md-theme-default-primary": "#825a2c",
    "--md-theme-default-accent": "#825a2c",
    "--bg-alternate": "#825a2c",
    name: "Marron",
    id: 17,
  },
  {
    "--primary": "#3d3d3d",
    "--bg-primary": "#3d3d3d",
    "--bg-primary-blur": "#3d3d3d7a",
    "--bg-secondary": "#3d3d3d",
    "--bg-third": "#000",
    "--text-alternate": "#eeeeee",
    "--text-primary": "#fff",
    "--text-secondary": "#979797",
    "--color-icon-1": "#61c1a0",
    "--color-icon-2": "#a8d080",
    "--color-icon-3": "#fdda29",
    "--color-icon-4": "#e96061",
    "--color-icon-5": "#e6b6ce",
    "--color-icon-6": "#c0acd1",
    "--md-table-background": "#fff",
    "--md-theme-default-text-primary-on-accent": "#fff",
    "--md-theme-default-primary-on-background": "#3d3d3d",
    "--md-theme-default-primary": "#3d3d3d",
    "--md-theme-default-accent": "#3d3d3d",
    "--bg-alternate": "#3d3d3d",
    "--font": "Roboto-Regular",
    "--font-bold": "Roboto-Bold",
    "--font-extrabold": "Roboto-Bold",
    "--font-x": "Roboto-Light",
    "--font-medium": "Roboto-Medium",
    "--font-semibold": "Roboto-SemiBold",
    "--font-black": "Roboto-Black",
    name: "Rouge GC",
    id: 18,
  },
  {
    "--primary": "#35b5b7",
    "--bg-primary": "#002f87",
    "--bg-primary-blur": "#002f877a",
    "--bg-secondary": "#4c6dab",
    "--text-alternate": "#eeeeee",
    "--text-primary": "#fff",
    "--text-secondary": "#979797",
    "--md-theme-default-text-primary-on-accent": "#fff",
    "--md-theme-default-primary-on-background": "#7ed3c1",
    "--md-theme-default-primary": "#7ed3c1",
    "--md-theme-default-accent": "#7ed3c1",
    "--bg-alternate": "#002f87",
    "--font": "Gotham-Light",
    "--font-x": "Gotham-Light",
    "--font-bold": "Gotham-Bold",
    "--font-medium": "Gotham-Medium",
    "--font-book": "Gotham-Book",
    "--font-extrabold": "Gotham-Black",
    "--font-black": "Gotham-Black",
    name: "Bleu JEP",
    id: 19,
  },
  {
    "--primary": "#A24052",
    "--bg-primary": "#3c5665",
    "--bg-primary-blur": "#3c56657a",
    "--bg-secondary": "#979797",
    "--text-alternate": "#eeeeee",
    "--text-primary": "#fff",
    "--text-secondary": "#979797",
    "--md-table-background": "#fff",
    "--md-table-hover": "#AB3751",
    "--md-table-selected": "#B25567",
    "--md-theme-default-text-primary-on-accent": "#fff",
    "--md-theme-default-primary-on-background": "#A24052",
    "--md-theme-default-primary": "#A24052",
    "--md-theme-default-accent": "#912E3B",
    "--bg-alternate": "#3c5665",
    "--font": "Montserrat-Light",
    "--font-x": "Montserrat-Light",
    "--font-medium": "Montserrat-Medium",
    "--font-bold": "Montserrat-Bold",
    "--font-extrabold": "Montserrat-Bold",
    name: "Rouge CE",
    id: 20,
  },
  {
    "--primary": "#002575",
    "--secondary": "#000",
    "--bg-primary": "#002575",
    "--bg-primary-blur": "#0025757a",
    "--bg-third": "#002575",
    "--bg-secondary": "#f7d64b",
    "--text-alternate": "#002f87",
    "--text-primary": "#fff",
    "--text-secondary": "#000",
    "--text-bold": "#003382",
    "--text-placeholder": "#2a2a2a",
    "--md-table-background": "#fff",
    "--md-table-hover": "#FAEBB0",
    "--md-theme-default-text-primary-on-accent": "#002575",
    "--md-theme-default-primary-on-background": "#002575",
    "--md-theme-default-primary": "#f7d64b",
    "--md-theme-default-accent": "#002f87",
    "--bg-alternate": "#002f87",
    "--font": "Gotham-Light",
    "--font-bold": "Gotham-Bold",
    "--font-extrabold": "Gotham-Bold",
    "--font-medium": "Gotham-Medium",
    "--font-x": "Gotham-Light",
    "--font-book": "Gotham-Book",
    "--font-black": "Gotham-Black",
    "--font-x-light": "Gotham-XLight",
    name: "Jarige Particuliers",
    id: 21,
  },
  {
    "--primary": "#8ad9fc",
    "--secondary": "#000",
    "--bg-primary": "#8ad9fc",
    "--bg-primary-blur": "#8ad9fc7a",
    "--bg-secondary": "#c1e8fa",
    "--bg-alternate": "#fff",
    "--bg-import-error": "#FEA3AE",
    "--bg-import-selected": " #FBD6DC",
    "--bg-import-hover": "#FF6F81",
    "--bg-third": "#000",
    "--bg-error": "#8adbfc",
    "--text-alternate": "#002f87",
    "--text-primary": "#fff",
    "--text-secondary": "#8ad9fc",
    "--text-inverted": "#979797",
    "--md-theme-default-text-primary-on-accent": "#fff",
    "--md-theme-default-primary-on-background": "#2a2a2a",
    "--md-theme-default-fieldvariant": "#8ad9fc",
    "--md-theme-default-primary": "#f7d64b",
    "--md-theme-default-accent": "#002f87",
    "--font": "Nunito",
    "--font-bold": "Nunito-Bold",
    "--font-extrabold": "Nunito-ExtraBold",
    "--font-x": "Nunito-Light",
    name: "New MEEP",
    id: 22,
  },
];
