<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.31 32.913"><path d="M24.976 27.636a.679.679 0 00-.679.679v2.564a.677.677 0 01-.677.677H2.035a.678.678 0 01-.678-.677v-21.9l7.62-7.62H23.62a.677.677 0 01.677.677v9.668a.679.679 0 001.357 0V2.036A2.035 2.035 0 0023.619.001H8.696a.679.679 0 00-.48.2L.198 8.219a.678.678 0 00-.2.48v22.182a2.035 2.035 0 002.035 2.034h21.586a2.035 2.035 0 002.035-2.034v-2.564a.679.679 0 00-.678-.681z"/><path d="M8.018 4.069v3.949H4.069a.679.679 0 000 1.357h4.628a.679.679 0 00.679-.679V4.068a.679.679 0 10-1.357 0zM32.714 14.437a2.021 2.021 0 00-1.439-.6H12.932a2.034 2.034 0 00-2.035 2.034v8.427a2.035 2.035 0 002.035 2.035h18.344a2.035 2.035 0 002.035-2.035v-8.427a2.02 2.02 0 00-.597-1.434zm-.761 9.866a.677.677 0 01-.678.677H12.932a.678.678 0 01-.677-.677v-8.427a.677.677 0 01.677-.677h18.344a.677.677 0 01.678.677z"/><path d="M29.278 17.097h-2.764a.679.679 0 00-.679.679v4.622a.679.679 0 001.357 0v-1.7h1.565a.679.679 0 100-1.357h-1.565v-.889h2.086a.679.679 0 100-1.357zM16.901 17.097h-1.973a.679.679 0 00-.679.679v4.622a.679.679 0 001.357 0v-1.015H16.9a2.115 2.115 0 002.113-2.113v-.06a2.115 2.115 0 00-2.112-2.113zm.756 2.173a.757.757 0 01-.756.756h-1.294v-1.572h1.294a.756.756 0 01.756.756zM21.901 17.097h-1.494a.679.679 0 00-.679.679v4.622a.679.679 0 00.679.679h1.494a2.99 2.99 0 000-5.98zm0 4.622h-.815v-3.265h.815a1.633 1.633 0 010 3.265z"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconCloudPdf'
  };
</script>
