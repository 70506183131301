import {
  LIST_TRASH_USERS,
  LIST_UNVERIFIED_USERS,
  LIST_USERS,
} from "./users.actions";

const getters = {
  [LIST_USERS]: (state) => {
    return state[LIST_USERS];
  },
  [LIST_UNVERIFIED_USERS]: (state) => {
    return state[LIST_UNVERIFIED_USERS];
  },
  [LIST_TRASH_USERS]: (state) => {
    return state[LIST_TRASH_USERS];
  },
};

export default getters;
