<template>
  <md-toolbar class="action-bar md-accent" :md-elevation="2">
    <div class="md-toolbar-row">
      <!-- Le bouton de retour (à gauche) -->
      <div class="md-toolbar-section-start">
        <md-button @click.native="$emit('back')" v-once>
          <md-icon class="md-accent">arrow_back</md-icon>
          <span class="md-small-hide md-accent">Retour</span>
        </md-button>
      </div>

      <span v-if="hasTitle" class="md-title text-^r md-accent">
        {{ title }}
      </span>

      <!-- La barre de recherche (au milieu) -->
      <md-autocomplete
        class="md-small-hide search-bar-desktop md-focused"
        v-if="hasSearchBar"
        v-model="search"
        :md-options="[]"
        md-layout="box"
        @input="onInput"
      >
        <label>
          <md-icon> search </md-icon>
          {{ searchBarPlaceholder }}
        </label>
      </md-autocomplete>

      <!-- Les boutons d'action (à droite) -->
      <div class="md-toolbar-section-end">
        <!-- Les petits boutons d'action -->
        <div v-if="smallActionButtons && smallActionButtons.length">
          <md-button
            class="md-icon-button"
            :class="{
              'md-primary': button.isVisible(),
              none: !button.isVisible(),
            }"
            v-for="(button, index) in smallActionButtons"
            :key="index"
            @click.native="$emit(button.actionName)"
          >
            <span v-if="button.isVisible()">
              <md-icon v-if="button.isSVG" md-src="button.icon"></md-icon>
              <md-icon v-else>{{ button.icon }}</md-icon>
              <md-tooltip md-direction="bottom">{{ button.label }}</md-tooltip>
            </span>
          </md-button>
        </div>
        <div style="margin: 0 10px">
          <!-- Le bouton d'action principal -->
          <md-button
            v-if="hasActionButton"
            class="mode-btn md-raised"
            @click.native="$emit('action')"
            :disabled="isActionButtonDisabled"
            :class="{
              'meep-disabled': isActionButtonDisabled,
              'md-secondary': !isActionButtonDisabled,
            }"
          >
            <md-icon
              class="md-secondary"
              v-if="isActionButtonIconSVG"
              md-src="actionButtonIcon"
            ></md-icon>
            <md-icon class="md-secondary" v-else>{{
              actionButtonIcon
            }}</md-icon>
            <span class="md-small-hide">{{ actionButtonText }}</span>
          </md-button>
        </div>
        <!-- Le bouton 2 d'action principal -->
        <md-button
          v-if="hasActionSecondButton"
          class="mode-btn md-raised"
          :class="{
            'meep-disabled': isActionButtonDisabled,
            'md-secondary': !isActionButtonDisabled,
          }"
          @click.native="$emit('actionSecond')"
          :disabled="isActionSecondButtonDisabled"
        >
          <span class="md-small-hide">{{ actionSecondButtonText }}</span>
          <md-icon
            class="md-secondary"
            v-if="isActionSecondButtonIconSVG"
            md-src="actionSecondButtonIcon"
          ></md-icon>
          <md-icon class="md-secondary" v-else>{{
            actionSecondButtonIcon
          }}</md-icon>
        </md-button>
      </div>
    </div>

    <!-- La barre de recherche (en bas pour les mobiles) -->
    <div v-if="hasSearchBar" class="md-toolbar-row search-bar-mobile">
      <md-autocomplete
        class="search"
        v-model="search"
        :md-options="[]"
        md-layout="box"
        @input="onInput"
      >
        <label>
          <md-icon>search</md-icon>
          {{ searchBarPlaceholder }}
        </label>
      </md-autocomplete>
    </div>
  </md-toolbar>
</template>

<script>
export default {
  props: {
    /* Condition pour afficher la barre de recherche */
    hasSearchBar: {
      type: Boolean,
      required: false,
      default: false,
    },
    /* Le texte à afficher lorsqu'il n'y a rien dans la barre de recherche */
    searchBarPlaceholder: {
      type: String,
      default: "Rechercher",
    },
    /* Condition pour afficher le bouton d'action */
    hasActionButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasActionSecondButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    /* Texte du bouton d'action */
    actionButtonText: {
      type: String,
      required: false,
      default: "Action",
    },
    actionSecondButtonText: {
      type: String,
      required: false,
      default: "Action",
    },
    /* Condition pour afficher le titre */
    hasTitle: {
      type: Boolean,
      required: false,
      default: false,
    },
    /* Texte du titre */
    title: {
      type: String,
      required: false,
    },
    /* Icone du bouton d'action */
    actionButtonIcon: {
      type: String,
      required: false,
      default: "",
    },
    actionSecondButtonIcon: {
      type: String,
      required: false,
      default: "",
    },
    /* Si l'icone du bouton d'action est un .svg ou fait partie d'une police de caractères */
    isActionButtonIconSVG: {
      type: Boolean,
      required: false,
      default: false,
    },
    isActionButtonDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isActionSecondButtonIconSVG: {
      type: Boolean,
      required: false,
      default: false,
    },
    isActionSecondButtonDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    /* La liste des petites icones,
     ** contient une liste d'objets ayant la structure suivante :
     **
     ** {
     **		isVisible: Boolean, <- La condition pour afficher le bouton
     **		actionName: String, <- Le nom de l'action à émettre lors d'un clic
     **		isSVG: Boolean,     <- Si l'icone est un .svg
     **		icon: String        <- Le chemin ou le nom de l'icone
     **		label: String       <- Le label qui s'affiche lorqu'on sur le bouton avec la souris
     ** }
     */
    smallActionButtons: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      search: "",
    };
  },
  methods: {
    onInput() {
      this.$emit("input", this.search);
    },
  },
};
</script>

<style lang="scss" scoped>
.action-bar {
  position: sticky;
  position: -webkit-sticky;
  top: 0;

  .search-bar-desktop {
    width: 50%;
    margin-right: 16px !important;
  }

  .search-bar-mobile {
    width: 100%;
    margin-right: 10px !important;

    @media screen and (min-width: 945px) {
      display: none;
    }
  }

  .mode-btn {
    background-color: white;
  }

  .none {
    display: none;
  }
}
</style>
