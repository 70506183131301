<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.539 21.932"><path d="M14.417 17.096a.51.51 0 100 1.02h4.718a.51.51 0 100-1.02zm0-2.55a.51.51 0 100 1.02h4.718a.51.51 0 100-1.02zm-10.688-.709a.527.527 0 00-.369.152.5.5 0 000 .717l1.671 1.626-1.671 1.626a.5.5 0 000 .717.53.53 0 00.737 0l1.671-1.626 1.671 1.626a.529.529 0 00.737 0 .5.5 0 000-.717l-1.671-1.626 1.671-1.626a.5.5 0 000-.717.53.53 0 00-.737 0l-1.671 1.625-1.671-1.625a.527.527 0 00-.369-.152m8.067-2.358h9.7v5.866a3.6 3.6 0 01-3.669 3.57h-6.033zm-10.745 0h9.7v9.436H4.717a3.6 3.6 0 01-3.669-3.57zm13.367-6.383a.51.51 0 100 1.02h4.718a.51.51 0 100-1.02zm-8.649-2.3a.518.518 0 00-.524.51v1.785H3.407a.51.51 0 100 1.02h1.835v1.785a.524.524 0 001.048 0V6.111h1.834a.51.51 0 100-1.02H6.29V3.306a.518.518 0 00-.524-.51m6.027-1.785h6.024a3.6 3.6 0 013.669 3.571v5.865h-9.7zm-7.076 0h6.028v9.436h-9.7V4.582a3.6 3.6 0 013.669-3.571m0-1.02A4.657 4.657 0 00-.004 4.582v12.751a4.657 4.657 0 004.718 4.59h13.103a4.656 4.656 0 004.718-4.59V4.582a4.656 4.656 0 00-4.718-4.586h-13.1z"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconSimulateurs'
  };
</script>
