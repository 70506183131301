<template>
  <div
    id="dash-menu"
    class="dash-menu"
    :class="menuClass"
    @mouseenter="onMouseDashBoard"
    @mouseleave="onMouseLeaveDashBoard"
  >
    <button
      class="dash-menu__logo-wrapper"
      type="button"
      @click="goToHomePage()"
    >
      <MeepIconLogoExpertsa
        v-if="isSuperUser"
        class="dash-menu__logo"
        data-test-icon-expert-sa
      />

      <md-icon
        v-else-if="logo"
        id="organization-logo"
        class="md-size-5x"
        data-test-icon-organization
      >
        <div v-html="logoMinified" />
      </md-icon>

      <MeepIconLogoMeep v-else class="dash-menu__logo" data-test-icon-meep />
      <!-- Logo must be 220 x 128 px -->
    </button>

    <md-list>
      <!-- Super User Menu -->
      <template v-if="isSuperUser">
        <SuperUserMenu :listIdMenu="sideBarSuperUser" />
      </template>

      <!-- Normal User Menu -->
      <template v-else>
        <NormalUserMenu :listIdMenu="sideBarIds" data-normal-user-menu />
      </template>
    </md-list>
  </div>
</template>

<script>
import MeepIconLogoMeep from "@/components/icons/MeepIconLogoMeep";
import MeepIconLogoExpertsa from "@/components/icons/MeepIconLogoExpertsa";
import NormalUserMenu from "@/components/normal-user-menu.vue";
import SuperUserMenu from "@/components/super-user-menu.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import config from "../config";
import { Menu } from "@/dto/menu.enum";
import { SuperMenu } from "@/dto/super-menu.enum";

export default {
  name: "DashMenu",

  components: {
    MeepIconLogoMeep,
    MeepIconLogoExpertsa,
    NormalUserMenu,
    SuperUserMenu,
  },

  data() {
    return {
      config,
      isSmallIcon: true,
    };
  },

  computed: {
    ...mapGetters([
      "isAdmin",
      "isCollab",
      "isExternal",
      "isSuperUser",
      "isClient",
      "isEmployee",
      "logo",
      "logoMinified",
      "hasPushs",
      "hasCloud",
      "canChat",
      "isGC",
      "isJEP",
      "isCE",
      "isJEPA",
      "isLCM",
      "androidUrl",
      "iOsUrl",
      "isMEEP",
    ]),
    ...mapState(["conversations", "isShowCloudEmployee"]),
    showSetting() {
      return this.isAdmin || this.isCollab || (this.isClient && !this.isJEPA);
    },

    innerWidth() {
      return window.innerWidth;
    },

    showCloud() {
      if (this.isEmployee) {
        return this.hasCloud && this.isShowCloudEmployee;
      }
      return this.hasCloud;
    },

    showCompanies() {
      return (this.isAdmin || this.isCollab) && !this.isJEPA;
    },

    showUsers() {
      return this.isAdmin || this.isCollab || (this.isClient && !this.isJEPA);
    },

    showGroups() {
      return this.isAdmin;
    },

    showSocials() {
      return (
        (this.isAdmin && !this.isJEPA) ||
        (this.isClient && !this.isJEPA) ||
        (this.isCollab && !this.isJEPA)
      );
    },

    showChat() {
      return (
        this.isAdmin ||
        this.isCollab ||
        this.isClient ||
        this.conversations.length
      );
    },

    showTask() {
      return (this.isAdmin || this.isCollab) && !this.isJEPA;
    },

    showNotes() {
      return (
        (this.isCollab || this.isClient || this.isAdmin) &&
        !this.isJEPA &&
        !this.isLCM
      );
    },
    showTax() {
      return !this.isCollab && (this.isJEPA && !this.isAdmin);
    },

    showEmployees() {
      return (
        (this.isCollab || this.isClient || this.isAdmin) &&
        !this.isJEP &&
        !this.isJEPA
      );
    },

    showSimulaterus() {
      return (
        (this.isCollab || this.isClient || this.isAdmin || this.isEmployee) &&
        !this.isJEP &&
        !this.isJEPA
      );
    },

    showListAccounting() {
      return this.isAdmin || this.isCollab;
    },

    showExpertSANews() {
      return this.isAdmin || this.isCollab || this.isClient || this.isEmployee;
    },

    showPush() {
      return this.isAdmin && this.hasPushs;
    },

    menuClass() {
      return {
        "dash-menu-gc": this.isGC,
        "dash-menu-jep": this.isJEP,
        "dash-menu-jepa": this.isJEPA,
        "dash-menu-meep": this.isMEEP,
        "dash-menu-lcm": this.isLCM,
        "dash-menu-ce": this.isCE,
      };
    },
    sideBarIds() {
      const sideBarUserMenu = [
        {
          id: Menu.SETTING,
          isShow: this.showSetting,
        },
        {
          id: Menu.SOCIALS,
          isShow: this.showSocials,
        },
        {
          id: Menu.CHAT,
          isShow: this.showChat,
        },
        {
          id: Menu.CLOUD,
          isShow: this.showCloud,
        },
        {
          id: Menu.TAX,
          isShow: this.showTax,
        },
        {
          id: Menu.TASK,
          isShow: this.showTask,
        },
        {
          id: Menu.NOTES,
          isShow: this.showNotes,
        },
        {
          id: Menu.ACCOUNTING_PLAN,
          isShow: this.showListAccounting,
        },
        {
          id: Menu.SIMULATEURS,
          isShow: this.showSimulaterus,
        },
        {
          id: Menu.NEWS,
          isShow: this.showExpertSANews,
        },
      ];

      let sideBar = sideBarUserMenu.filter((menu) => menu.isShow).map((menu) => menu.id);

      if (this.isClient && this.isJEPA) {
        sideBar = [Menu.SETTING_JEPA, Menu.CHAT_JEPA, Menu.CLOUD, Menu.NEWS]
      }
      return sideBar;
    },
    sideBarSuperUser() {
      const sideBarSuperUserMenu = [
        {
          id: SuperMenu.ORGANIZATIONS,
          isShow: true,
        },
        {
          id: SuperMenu.ADMINISTRATORS,
          isShow: true,
        },
        {
          id: SuperMenu.CALCULATORS,
          isShow: true,
        },
        {
          id: SuperMenu.TRASH,
          isShow: this.isSuperUser,
        },
      ]
      const sideBar = sideBarSuperUserMenu.filter((menu) => menu.isShow).map((menu) => menu.id);
      return sideBar;
    }
  },
  async mounted() {
    this.$store.dispatch("CHECK_CLOUD_EMPLOYEE");
    if (this.logo && this.canChat) {
      this.GET_NUMBER_OF_UNREAD_MESSAGES();
    }
  },

  methods: {
    ...mapActions(["GET_NUMBER_OF_UNREAD_MESSAGES"]),

    goToHomePage() {
      let path;
      if (this.isSuperUser) {
        path = "/admin";
      } else {
        path = "/dashboard/home";
      }

      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },

    onMouseDashBoard() {
      document.getElementById("dash-menu").classList.add("active");

      if (window.innerWidth <= 1919) {
        document.getElementById("drawer").style.width = "144px";
      } else {
        document.getElementById("drawer").style.width = "220px";
      }

      this.isSmallIcon = false;
    },

    onMouseLeaveDashBoard() {
      document.getElementById("dash-menu").classList.remove("active");
      if (window.innerWidth <= 1919) {
        document.getElementById("drawer").style.width = "70px";
        document.getElementsByClassName(
          "md-app-container"
        )[0].style.paddingLeft = "70px";
      } else {
        document.getElementById("drawer").style.width = "100px";
        document.getElementsByClassName(
          "md-app-container"
        )[0].style.paddingLeft = "100px";
      }
      this.isSmallIcon = true;
    },
  },
};
</script>

<style lang="scss">
@import "../styles/color";
@import "../styles/_variable.scss";
.md-theme-default {
  .dash-menu {
    height: 100%;
    &.dash-menu-gc {
      .md-list {
        .dash-menu__list-item {
          .md-list-item-text {
            font-size: toRem(10);
            font-family: var(--font);
            font-weight: 400;
            @include for-lg {
              font-size: toRem(12);
            }
          }
          &:nth-child(6n + 1) {
            .dash-menu__icon {
              path {
                fill: var(--color-icon-1) !important;
              }
            }
            .dash-menu__icon--stroke {
              path {
                stroke: var(--color-icon-1) !important;
                fill: transparent;
              }
            }
          }
          &:nth-child(6n + 2) {
            .dash-menu__icon {
              path {
                fill: var(--color-icon-2) !important;
              }
            }
          }
          &:nth-child(6n + 3) {
            .dash-menu__icon {
              path {
                fill: var(--color-icon-3) !important;
              }
            }
            .dash-menu__icon--stroke {
              path {
                stroke: var(--color-icon-3) !important;
                fill: transparent;
              }
            }
          }
          &:nth-child(6n + 4) {
            .dash-menu__icon--stroke {
              path {
                stroke: var(--color-icon-4) !important;
              }
            }
          }
          &:nth-child(6n + 5) {
            .dash-menu__icon--stroke {
              path {
                stroke: var(--color-icon-5) !important;
                fill: transparent;
              }
              circle {
                stroke: var(--color-icon-5) !important;
              }
            }
            .dash-menu__icon {
              path {
                fill: var(--color-icon-5) !important;
              }
            }
          }
          &:nth-child(6n) {
            .dash-menu__icon {
              path {
                fill: var(--color-icon-6) !important;
              }
            }
          }
        }
        .dash-menu__list-item--user,
        .dash-menu__list-item--task {
          .dash-menu__icon {
            path {
              fill: $gc-orange !important;
            }
          }
        }
        .dash-menu__list-item--group,
        .dash-menu__list-item--organization-news {
          .dash-menu__icon {
            path {
              fill: $gc-green !important;
            }
          }
        }
        .dash-menu__list-item--cloud,
        .dash-menu__list-item--news {
          .dash-menu__icon {
            path {
              fill: $gc-pink !important;
            }
          }
        }
        .dash-menu__list-item--chat,
        .dash-menu__list-item--organization-company {
          .dash-menu__icon {
            path {
              fill: $gc-red !important;
            }
          }
        }
      }
    }
    // CSS dash-icon in CE web
    &.dash-menu-ce {
      .md-list {
        .md-list-item-text {
          font-size: toRem(10);
          font-family: var(--font);
          font-weight: bold;
          @include for-lg {
            font-size: toRem(12);
          }
        }
        .dash-menu__icon {
          path {
            fill: var(--primary) !important;
          }
        }
        .dash-menu__icon--stroke {
          path {
            stroke: var(--primary) !important;
          }
          &.app-icon-contains-g {
            circle {
              stroke: var(--primary);
            }
          }
        }
      }
    }
    &.dash-menu-jep {
      .md-list.md-theme-default {
        .md-list-item.dash-menu__list-item {
          .md-list-item-text {
            color: #000;
            font-weight: bold;
            font-family: var(--font);
            font-size: toRem(10);
            @include for-lg {
              font-size: toRem(12);
            }
          }
        }
      }
      .dash-menu__list-link {
        a {
          color: $cl-alt-jep;
          font-weight: bold;
        }
      }
    }
    &.dash-menu-jepa {
      .dash-menu__list-item {
        .md-list-item-content {
          justify-content: flex-start;
          .md-list-item-text {
            font-family: var(--font);
            color: #000;
            font-size: toRem(10);
            line-height: 1.15;
            max-width: 95px;
            @include for-lg {
              font-size: toRem(12);
            }
          }
        }
      }
      .dash-menu__list-link {
        a {
          font-family: Gotham-Bold;
          font-size: 12px;
          color: #003382;
          line-height: 1.2;
        }
      }
    }
    &.dash-menu-meep {
      .dash-menu__icon--stroke {
        path {
          stroke: var(--bg-third) !important;
        }
      }
      .dash-menu__icon {
        color: var(--bg-third);
        path {
          fill: var(--bg-third) !important;
        }
        &-text.md-icon.md-theme-default {
          color: var(--bg-third);
          transition: none;
          &:hover {
            color: var(--bg-third);
          }
        }
      }
    }
    &.dash-menu-lcm {
      .dash-menu__icon--stroke {
        path {
          stroke: var(--secondary) !important;
        }
      }
      .dash-menu__icon {
        path {
          fill: var(--secondary) !important;
        }
      }
      .dash-menu__list-item {
        .md-list-item-text {
          font-size: toRem(10);
          font-family: var(--font);
          font-weight: 400;
          @include for-lg {
            font-size: toRem(12);
          }
        }
      }
    }
    &__logo {
      width: 100%;
      height: 60px;
    }
    &__logo-wrapper {
      width: 100%;
      background: none;
      border: none;
      padding: 0;
      margin: 0 0 20px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    &__icon--stroke {
      path {
        stroke: var(--bg-primary) !important;
        fill: transparent;
      }
    }
    &__icon {
      width: 18px;
      height: 18px;
      color: var(--bg-primary);
      @include for-lg {
        width: 24px;
        height: 24px;
      }
      &--stroke {
        width: 18px;
        height: 18px;
        @include for-lg {
          width: 24px;
          height: 24px;
        }
      }
      path {
        fill: var(--bg-primary) !important;
      }
      &-text.md-icon.md-theme-default {
        color: var(--bg-primary);
        transition: none;
        &:hover {
          color: var(--text-primary);
        }
      }
    }
    .router-link-active {
      background-color: #f2f5f7;
      border-radius: 10px;
    }
    &__list-item.md-list-item {
      font-size: 16px;
      text-transform: uppercase;
      border-radius: 10px;
      color: var(--bg-disabled);
      margin: 4px auto;
      transition: margin 1s ease-in-out;
      .md-list-item-text {
        white-space: normal;
        width: 0;
        font-size: toRem(10);
        font-family: var(--font-bold);
        transition: all 1s ease-in-out;
        @include for-lg {
          font-size: toRem(14);
        }
      }
      .md-list-item-router {
        border-radius: 10px;
        height: 35px;
        @include for-lg {
          height: 51px;
        }
        &:hover {
          background-color: #f2f5f7;
          border-radius: 10px;
        }
        .md-list-item-content {
          padding: 4px;
          min-height: 35px;

          @include for-lg {
            padding: 4px 16px;
          }
        }
      }
    }
    &__list-app {
      width: 100%;
      padding: 4px 10px;
      a {
        img {
          width: 100%;
        }
        display: block;
        position: relative;
        max-width: 170px;
        margin: 0 auto 20px auto;
      }
    }
    &__list-link {
      width: 100%;
      text-align: center;
      .router-link-active {
        background-color: transparent;
      }
    }
    &__app-icon {
      margin-right: 20px;
    }
    &.active {
      .md-list-item {
        transition: all 0.5s ease-in-out;
        width: 85%;
        @include for-lg {
          width: 80%;
        }
        .md-list-item-text {
          display: block;
        }
      }
      .dash-menu__icon,
      .dash-menu__icon--stroke {
        margin-right: toRem(10);
        @include for-lg {
          margin-right: toRem(18);
        }
      }
    }
    #organization-logo {
      overflow: hidden;
      svg {
        width: 100%;
        height: auto;
      }
    }
  }
}
.icon-menu {
  padding: 0 20px 0 0;
}
</style>
