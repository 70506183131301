<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.309 32.913"><path d="M24.976 27.636a.679.679 0 00-.678.679v2.564a.677.677 0 01-.677.677H2.036a.677.677 0 01-.678-.677v-21.9l7.62-7.62h14.643a.678.678 0 01.677.678v9.667a.679.679 0 001.357 0V2.037A2.035 2.035 0 0023.62.002H8.696a.679.679 0 00-.48.2L.198 8.22a.678.678 0 00-.2.48v22.182a2.035 2.035 0 002.035 2.034h21.585a2.034 2.034 0 002.035-2.034v-2.564a.679.679 0 00-.677-.682z"/><path d="M8.018 4.069v3.949H4.069a.679.679 0 000 1.357h4.628a.679.679 0 00.679-.679V4.068a.679.679 0 00-1.357 0zM32.713 14.437a2.021 2.021 0 00-1.439-.6H12.93a2.035 2.035 0 00-2.035 2.035v8.427a2.035 2.035 0 002.035 2.035h18.344a2.035 2.035 0 002.035-2.035v-8.427a2.021 2.021 0 00-.596-1.435zm-.761 9.866a.677.677 0 01-.678.677H12.93a.677.677 0 01-.677-.677v-8.427a.677.677 0 01.677-.677h18.344a.678.678 0 01.678.677z"/><path d="M16.486 18.435a1.416 1.416 0 011.046.437.679.679 0 00.959-.96 2.783 2.783 0 00-2-.834 1.9 1.9 0 00-2.124 1.866c0 1.417 1.353 1.669 2 1.791.9.168.9.283.9.457 0 .338-.258.509-.768.509a1.414 1.414 0 01-1.045-.437.678.678 0 00-.959.96 2.779 2.779 0 002 .834 1.9 1.9 0 002.125-1.866c0-1.416-1.354-1.669-2-1.791-.9-.168-.9-.283-.9-.457-.001-.338.257-.509.766-.509zM29.236 19.629h-1.047a.679.679 0 000 1.357h.368v.53a1.354 1.354 0 01-2.036-1.169v-.558a1.354 1.354 0 012.257-1.008.679.679 0 10.907-1.009 2.711 2.711 0 00-4.522 2.017v.558a2.711 2.711 0 004.527 2.012.68.68 0 00.224-.5v-1.548a.678.678 0 00-.678-.682zM24.534 17.149a.678.678 0 00-.909.307l-1.684 3.4-1.683-3.4a.679.679 0 10-1.216.6l2.291 4.623a.679.679 0 001.216 0l2.291-4.623a.678.678 0 00-.306-.907z"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconCloudSvg'
  };
</script>
