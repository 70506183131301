import Vue from "vue";
import { getFileExtension } from "./services/util";
import { format, parse, parseISO, startOfMonth } from "date-fns";
import MeepIconCloudFile from "@/components/icons/MeepIconCloudFile.vue";
import MeepIconCloudFolder from "@/components/icons/MeepIconCloudFolder.vue";
import MeepIconCloudJpg from "@/components/icons/MeepIconCloudJpg.vue";
import MeepIconCloudPdf from "@/components/icons/MeepIconCloudPdf.vue";
import MeepIconCloudPng from "@/components/icons/MeepIconCloudPng.vue";
import MeepIconCloudSvg from "@/components/icons/MeepIconCloudSvg.vue";
import MeepIconCloudXls from "@/components/icons/MeepIconCloudXls.vue";
import MeepIconCloudZip from "@/components/icons/MeepIconCloudZip.vue";
import MeepIconLock from "@/components/icons/MeepIconLock.vue";
import MeepIconSettingCompanies from "@/components/icons/MeepIconSettingCompanies.vue";
import fr from "date-fns/locale/fr-CA";
import _ from "lodash";

function formatDate(date, formatString = null) {
  let d;

  if (!(formatString instanceof String)) {
    formatString = null;
  }
  if (!date) {
    return "";
  }
  if (date instanceof Date) {
    d = date;
  } else {
    d = formatString ? parse(date, formatString, new Date()) : parseISO(date);
  }

  const day = format(d, "dd");

  const month = _.capitalize(format(d, "MMMM", { locale: fr }));

  const year = format(d, "yyyy");

  return `${day} ${month} ${year}`;
}

export function formatDateWithoutYear(date, formatString = null) {
  if (!(formatString instanceof String)) {
    formatString = null;
  }
  if (!date) {
    return "";
  }
  const d = formatString
    ? parse(date, formatString, new Date())
    : parseISO(date);

  const day = format(d, "dd");

  const month = _.capitalize(format(d, "MMMM", { locale: fr }));

  return `${day} ${month}`;
}

function formatFullMonth(item) {
  return format(startOfMonth(new Date()).setMonth(item), "LLLL", {
    locale: fr,
  });
}

function formatTimestamp(timestamp, formatString = null) {
  if (!(formatString instanceof String)) {
    formatString = null;
  }
  if (!timestamp) {
    return "";
  }
  const d = formatString
    ? parse(timestamp, formatString, new Date())
    : parseISO(timestamp);

  const day = format(d, "dd");

  const month = _.capitalize(format(d, "MMMM", { locale: fr }));

  const year = format(d, "yyyy");

  const hour = format(d, "HH");

  const minute = format(d, "mm");

  return `${day} ${month} ${year}, ${hour}:${minute}`;
}

function formatTimestampInChat(timestamp, formatString = null) {
  if (!(formatString instanceof String)) {
    formatString = null;
  }
  if (!timestamp) {
    return "";
  }
  const d = formatString
    ? parse(timestamp, formatString, new Date())
    : parseISO(timestamp);

  const day = format(d, "dd");

  const month = _.capitalize(format(d, "MMMM", { locale: fr }));

  const year = format(d, "yyyy");

  const hour = format(d, "HH");

  const minute = format(d, "mm");

  return window.innerHeight > 1280
    ? `${day} ${month} ${year}, ${hour}:${minute}`
    : `${day} ${month} ${year} <br/> ${hour}:${minute}`;
}

function formatHour(date) {
  const d = new Date(date);
  const today = new Date();

  if (today.toDateString() == d.toDateString()) {
    const hours = d.getHours();
    const minutes = d.getMinutes();
    const fHours = hours < 10 ? "0" + hours : hours;
    const fMinutes = minutes < 10 ? "0" + minutes : minutes;

    return `Aujourd'hui, ${fHours}:${fMinutes}`;
  } else {
    return formatTimestamp(date);
  }
}

function formatNumber(num) {
  if (num) {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
  } else {
    return "0.00";
  }
}

export function formatScope(scope) {
  switch (scope) {
    case 1:
      return "Administrateur";
    case 2:
      return "Collaborateur";
    case 3:
      return "Externe";
    case 4:
      return "Client";
    case 5:
      return "Employé";
    case 6:
      return "Expert SA";
  }
}

function formatPaid(status) {
  return status ? "Payée" : "À payer";
}

function formatExtension(file) {
  if (file.is_directory) {
    if (!file.parent_directory) {
      return "business_center";
    } else if (file.is_protected) {
      return "folder_special";
    } else {
      return "folder";
    }
  } else {
    return getFileExtension(file.file_name);
  }
}

function formatLock(file) {
  if (file.is_locked) {
    return "lock";
  } else return "";
}

/**
 *  Converting file size in bytes to human-readable string
 *  https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
 */
function formatFileSize(bytes, si) {
  var thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }
  var units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  var u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);

  return bytes.toFixed(1) + " " + units[u];
}

function getIconItem(text) {
  switch (text) {
    case "jpg":
    case "jpeg":
      return MeepIconCloudJpg;
    case "png":
      return MeepIconCloudPng;
    case "svg":
      return MeepIconCloudSvg;
    case "zip":
      return MeepIconCloudZip;
    case "xlsx":
      return MeepIconCloudXls;
    case "pdf":
      return MeepIconCloudPdf;
    case "folder":
      return MeepIconCloudFolder;
    case "folder_special":
      return MeepIconCloudFolder;
    case "business_center":
      return MeepIconSettingCompanies;
    case "lock":
      return MeepIconLock;
    default:
      return MeepIconCloudFile;
  }
}

function noAccent(s) {
  let r = s.toLowerCase();
  r = r.replace(new RegExp("[àáâãäå]", "g"), "a");
  r = r.replace(new RegExp("æ", "g"), "ae");
  r = r.replace(new RegExp("ç", "g"), "c");
  r = r.replace(new RegExp("[èéêë]", "g"), "e");
  r = r.replace(new RegExp("[ìíîï]", "g"), "i");
  r = r.replace(new RegExp("[òóôõö]", "g"), "o");
  r = r.replace(new RegExp("œ", "g"), "oe");
  r = r.replace(new RegExp("[ùúûü]", "g"), "u");
  return r;
}

/**
 * Remove unsupported characters from file's name
 */
function fileNameFilter(s) {
  let result = s.replace(/[&@^\\/\\[\]#,+|()$%~@%'"`:;!*?<>{}=]/g, "");
  if(!result.length){
    return "_";
  }
  return result;
}

// using $$ to avoid conflict in vue components
Vue.prototype.$$filters = {
  formatDate,
  formatDateWithoutYear,
  formatTimestamp,
  formatTimestampInChat,
  formatHour,
  formatNumber,
  formatPaid,
  formatScope,
  formatExtension,
  formatFileSize,
  noAccent,
  fileNameFilter,
  formatFullMonth,
  getIconItem,
  formatLock,
};
