<template>
  <div id="app" :class="appClass">
    <router-view></router-view>
    <popups></popups>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",

  computed: {
    ...mapGetters(["isLCM", "isJEP", "isCE", "isJEPA", "isMEEP", "isGC"]),

    appClass() {
      return {
        "app-lcm": this.isLCM,
        "app-jep": this.isJEP,
        "app-ce": this.isCE,
        "app-jepa": this.isJEPA,
        "app-meep": this.isMEEP,
        "app-gc": this.isGC,
      };
    },
  },

  async mounted() {
    await this.FETCH_CONFIG();
    window.onresize = () => {
      if (window.innerWidth <= 1919) {
        document.getElementById("drawer").style.width = "70px";
        document.getElementsByClassName(
          "md-app-container"
        )[0].style.paddingLeft = "70px";
        if (document.getElementById("dash-menu").classList.contains("active")) {
          document.getElementById("drawer").style.width = "144px";
        }
      } else {
        document.getElementById("drawer").style.width = "100px";
        document.getElementsByClassName(
          "md-app-container"
        )[0].style.paddingLeft = "100px";

        if (document.getElementById("dash-menu").classList.contains("active")) {
          document.getElementById("drawer").style.width = "220px";
        }
      }
    };

    const loader = document.querySelector(".main-loader");

    if (loader) {
      loader.style.display = "none";
    }
  },

  methods: {
    ...mapActions(["FETCH_CONFIG"]),
  },
};
</script>

<style>
#app {
  height: 100%;
}

.app-lcm {
  font-family: AvantGardEF, serif;
}

.app-ce {
  font-family: Montserrat-Medium, serif;
}
</style>
