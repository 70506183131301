import fetch from "../services/fetch";
import store from "../store";

export default {
  getConversations,
  getMessages,
  sendMessage,
  readAll,
  unreadCount,
  downloadMessages,
};

function getConversations() {
  return fetch("api/chat/", {
    method: "get",
    headers: { Authorization: store.state.token },
  });
}

function getMessages(companyID, unreadOnly, numberMessages, from, until) {
  return fetch("api/chat/get-messages", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      companyID,
      unreadOnly,
      numberMessages,
      from,
      until,
    }),
  });
}

function unreadCount() {
  return fetch("api/chat/unread-count", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
  });
}

function sendMessage(companyID, content) {
  return fetch("api/chat/", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      companyID,
      content,
    }),
  });
}

function downloadMessages(companyID) {
  return fetch("api/chat/download-messages/" + companyID, {
    method: "get",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
  });
}

function readAll(companyID) {
  return fetch("api/chat/" + companyID, {
    method: "put",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      companyID,
    }),
  });
}
