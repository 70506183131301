const TIMEOUT_MS = 40000000;

const state = () => {
  return {
    popups: [],
  };
};

const mutations = {
  ADD(state, popup) {
    state.popups.push(popup);
  },
  REMOVE(state, id) {
    state.popups = state.popups.filter((pop) => pop.id !== id);
  },
};

const actions = {
  REMOVE({ commit }, id) {
    commit("REMOVE", id);
  },
  ADD({ commit }, popup) {
    popup.id = Math.random();
    commit("ADD", popup);
    setTimeout(() => {
      commit("REMOVE", popup.id);
    }, popup.timeout || TIMEOUT_MS);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
