<template>
  <div class="search-bar" :class="modifier">
    <!-- Le champ de texte -->
    <input
      ref="input"
      type="text"
      class="search-input"
      :placeholder="placeholder"
      @keyup.enter="onEnter"
      @focus="onFocus"
      @input="onInput"
      @blur="onBlur"
    />

    <!-- L'icone de recherche -->
    <MeepIconSearchi class="search-bar-icon" />

    <!-- Le bouton pour vider le champ de texte -->
    <md-button
      class="close-btn md-icon-button"
      @click.native="reset"
      v-show="search"
    >
      <md-icon class="close-icon">
        close
      </md-icon>
    </md-button>
  </div>
</template>

<script>
import MeepIconSearchi from "@/components/icons/MeepIconSearchi";

export default {
  components: {
    MeepIconSearchi,
  },

  props: {
    placeholder: {
      type: String,
      default: "",
    },
    modifier: {
      type: String,
    },
  },
  data() {
    return {
      search: "",
    };
  },
  methods: {
    onEnter() {
      this.$emit("input", this.search);
      this.$emit("enter");
    },
    onInput(e) {
      const value = e.target.value;
      if (value !== this.search) {
        this.search = value;
        this.$refs.input.value = value;
        this.$emit("input", this.search);
      }
    },
    onBlur() {
      this.$emit("blur");
    },
    onFocus() {
      this.$emit("focus");
    },
    reset() {
      this.search = "";
      this.$refs.input.value = "";
      this.$emit("input", "");
      this.$emit("reset");
    },
  },
};
</script>

<style lang="scss">
.search-bar {
  border-radius: 2px;
  position: relative;
  height: 42px;
  width: 350px;
  margin-bottom: 20px;
  background-color: #f2f5f7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 1px 3px 2px -2px rgba(0, 0, 0, 0.1);

  &-icon {
    width: 20px;
    height: 20px;
    margin-right: 15px;

    path,
    g {
      stroke: var(--primary, #000);
      animation: all 1s ease-in-out;
    }
  }
}

.search-icon,
.close-icon {
  color: #a2a2a2;
  font-size: 24px;
}

.search-icon {
  margin: 0 16px;
}
.search-input {
  padding: 0;
  font-size: 17px;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  background: transparent;
  padding-left: 20px;
}

.search-bar.small {
  height: 40px;
  width: 240px;
  .search-icon {
    margin: 0 10px;
  }
  .close-btn {
    margin: 0;
  }
}

.search-bar.large {
  height: 42px;
  width: 100%;
  border-radius: 10px;

  .search-icon,
  .close-icon {
    color: #a2a2a2;
    font-size: 26px;
  }
  .search-icon {
    margin: 0 18px;
  }
}
</style>
