import config from "../config";
import { NOTIFICATIONS, USERS } from "../constants";
import uniqWith from "lodash/uniqWith";
import * as Sentry from "@sentry/vue";
import { formatDateWithoutYear } from "@/filters";

export const LOGIN_IN_PROGRESS = state => (state.isLoadingAuth = true);

export const LOGIN_SUCCESS = (state, { user, token, isSuperUser }) => {
  window.localStorage.setItem(config.token, token);
  window.localStorage.setItem(config.superuser, isSuperUser);

  state.token = token;
  state.isAuth = true;
  state.isLoadingAuth = false;
  state.self = user;

  Sentry.setUser({
    id: user.id,
    email: user.email,
    scope: user.scope,
    organization_id: user.organization_id,
  });

  const noChatScope = [USERS.SUPERUSER, USERS.EMPLOYEE];

  state.canChat = !noChatScope.includes(user.scope);
};

export const LOGIN_ERROR = state => {
  window.localStorage.removeItem(config.token);
  state.isAuth = false;
  state.isLoadingAuth = false;
  state.token = "";
  state.self = {};
  state.notifications = [];
};

export const UPDATE_SELF = (state, self) => {
  state.self = self;
};

export const SET_NOTIFICATIONS = (state, notifs) => {
  state.notifications = uniqWith(notifs, (a, b) => a.id === b.id);
};
export const READ_NOTIFICATION = (state, notifId) => {
  state.notifications = state.notifications.map(notif => {
    if (notif.id === notifId) notif.is_seen = true;
    return notif;
  });
};

export const READ_ALL_NOTIFICATIONS = (state, readChatOnly) => {
  state.notifications = state.notifications.map(notif => {
    if (readChatOnly && notif.notification_type === NOTIFICATIONS.CHAT) {
      notif.is_seen = true;
    } else {
      notif.is_seen = true;
    }
    return notif;
  });
};

export const REMOVE_NOTIFICATION = (state, notifId) => {
  state.notifications = state.notifications.filter(
    notif => notif.id !== notifId
  );
};

export const REMOVE_NOTIFICATION_CHAT = (state, companyID) => {
  state.notifications = state.notifications.filter(
    notif =>
      !(
        notif.notification_type === NOTIFICATIONS.CHAT &&
        notif.company_id === companyID
      )
  );
};

export const REMOVE_ALL_NOTIFICATIONS = state => {
  state.notifications = [];
};

export const SET_UNREAD_MESSAGES_COUNT = (state, unreadMessagesCount) => {
  state.unreadMessagesCount = unreadMessagesCount;
};

export const SELECT_ORGANIZATION = (state, { user, token }) => {
  window.localStorage.setItem(config.token, token);

  state.token = token;
  state.isAuth = true;
  state.self = user;

  if (user.organization && user.organization.logo) {
    window.localStorage.setItem("logo", user.organization.logo);
  }
};

export const SET_SOCKET_ID = (state, socketId) => (state.socketId = socketId);

export const SOCKET_SYNCED = state => (state.socketSynced = true);

export const SOCKET_DESYNCED = state => (state.socketSynced = false);

export const IS_MOBILE = (state, isMobile) => {
  state.isMobile = isMobile;
  state.showSnackbar = isMobile;
};

export const UPDATE_SHOW_SNACKBAR = (state, value) =>
  (state.showSnackbar = value);

export const TOGGLE_SHOW_CHAT = (state, { company_id, messages }) => {
  state.currentChatId = company_id;
  let groupByDateMess = { key: [], data: {} };
  messages.forEach(item => {
    let date = formatDateWithoutYear(item.created_at);
    if (!groupByDateMess.key.some(item => item === date)) {
      groupByDateMess.key.push(date);
      groupByDateMess.data[date] = [item];
    } else {
      groupByDateMess.data[date].push(item);
    }
  });

  state.messages = groupByDateMess;
};

export const TOGGLE_SHOW_CONVERSATION = (state, { conversations, is_show }) => {
  state.showConversation = is_show;
  state.conversations = conversations;
};

export const SHOW_CLOUD_EMPLOYEE = (state, payload) => {
  state.isShowCloudEmployee = payload;
};

export const ADD_MESSAGE = (state, message) => {
  let date = formatDateWithoutYear(new Date().toISOString());
  if (!state.messages.key.some(item => item === date)) {
    state.messages.key.push(date);
    state.messages.data[date] = [message];
  } else state.messages.data[date].push(message);
};

export const ADD_MESSAGES = (state, messages) => {
  messages.reverse().forEach(item => {
    let date = formatDateWithoutYear(item.created_at);
    if (!state.messages.key.some(item => item === date)) {
      state.messages.key.unshift(date);
      state.messages.data[date] = [item];
    } else {
      const dateChatLength = state.messages.data[date].length;
      if (
        item.created_at <
        state.messages.data[date][dateChatLength - 1].created_at
      ) {
        state.messages.data[date].unshift(item);
      } else state.messages.data[date].push(item);
    }
  });
};

export const UPDATE_MESSAGES = (state, messages) => {
  messages.forEach(item => {
    let date = formatDateWithoutYear(item.created_at);
    if (!state.messages.key.some(item => item === date)) {
      state.messages.key.push(date);
      state.messages.data[date] = [item];
    } else {
      state.messages.data[date] = state.messages.data[date].map(mess => {
        if (mess.id === item.id) {
          mess = item;
        }
        return mess;
      });
    }
  });
};

export const SET_LOAD_MESSAGE_MORE = (state, loadMessageMore) => {
  state.isLoadMessageMore = loadMessageMore;
};

export const SET_COLOR_SCHEME = (state, color_scheme) =>
  (state.color_scheme = color_scheme);

export const SET_LOGO = (state, logo) => (state.logo = logo);

export const SET_LOGO_MINIFIED = (state, logoMinified) =>
  (state.logoMinified = logoMinified);

export const SET_ORGANIZATION_ID = (state, id) => {
  state.organization_id = id;
};

export const SET_SITE_NAME = (state, name) => (state.site_name = name);

export const SET_ANDROID_URL = (state, androidUrl) =>
  (state.androidUrl = androidUrl);

export const SET_IOS_URL = (state, iOsUrl) => (state.iOsUrl = iOsUrl);

export const SET_IMPORT_COMPANIES = (state, data) => {
  state.newCompanies = data;
};

export const SET_IMPORT_USERS = (state, data) => {
  state.newUsers = data;
};

export const SET_IMPORT_ERROR_LINE = (state, data) => {
  state.importErrorLine = data;
};
