import config from "../config";

export default (url, options = {}) => {
  // default url is config.server.url + url
  let uri = url.startsWith("http") ? url : config.apiUrl + url;

  if (options.params) {
    uri += (uri.indexOf("?") === -1 ? "?" : "&") + queryParams(options.params);
    delete options.params;
  }

  return new Promise((resolve, reject) => {
    let timeout = null;

    if (options.timeout) {
      timeout = setTimeout(() => {
        reject({
          msg: "Une erreur est survenue, veuillez réessayer plus tard",
          code: 400,
        });
      }, options.timeout);
    }

    return fetch(uri, options)
      .then((response) => {
        if (options.timeout) {
          clearTimeout(timeout);
        }

        const contentType = response.headers.get("content-type");
        if (contentType) {
          if (contentType.indexOf("application/json") !== -1) {
            return response.json().then((json) => {
              return response.ok ? resolve(json) : reject(json);
            });
          }

          if (contentType.indexOf("application/octet-stream") !== -1) {
            return response.blob().then((blob) => {
              return response.ok ? resolve(blob) : reject(blob);
            });
          }
        }

        if (!response.ok && response.status === 413) {
          return reject({
            code: 413,
            msg:
              "Ce fichier est trop volumineux pour être téléchargé. La taille maximale des fichiers pris en charge est de 50 Mo.",
          });
        }

        return response.text().then((text) => {
          return response.ok ? resolve(text) : reject(text);
        });
      })
      .catch((error) => {
        if (options.timeout) {
          clearTimeout(timeout);
        }

        console.log(error);

        reject({
          msg:
            "Erreur de communication avec le serveur, vérifiez votre connexion Internet",
          code: -1,
        });
      });
  });
};

function queryParams(params) {
  return Object.keys(params)
    .filter((k) => params[k])
    .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
    .join("&");
}
