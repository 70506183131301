import fetch from "../services/fetch";
import config from "../config";

export default {
  signin,
  signin_admin,
  signup,
  verify,
  resetPassword,
  forgotPassword,
  resetPasswordAdmin,
  forgotPasswordAdmin,
};

function signin({ email, password, organization_id }) {
  return fetch("login/signin", {
    method: "post",
    timeout: config.authTimeout,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: email,
      password: password,
      organization_id: organization_id,
    }),
  });
}

function signin_admin({ email, password }) {
  return fetch("login/signin-admin", {
    method: "post",
    timeout: config.authTimeout,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  });
}

function signup(user) {
  return fetch("login/signup", {
    method: "post",
    timeout: config.authTimeout,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  });
}

function verify(token, isSuperUser) {
  return fetch("login/verify", {
    method: "post",
    timeout: config.authTimeout,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      token,
      isSuperUser,
    }),
  });
}

function forgotPassword(email, organization_id) {
  return fetch("login/forgot-password", {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email: email, organization_id: organization_id }),
  });
}

function resetPassword(form) {
  return fetch("login/reset-password", {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(form),
  });
}

function forgotPasswordAdmin(email) {
  return fetch("login/forgot-password-admin", {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email: email }),
  });
}

function resetPasswordAdmin(form) {
  return fetch("login/reset-password-admin", {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(form),
  });
}
