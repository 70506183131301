<template>
  <div class="footer" :class="footerClass">
    <div class="footer__copyright">
      {{ appName }} © {{ new Date().getFullYear() }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Footer",

  computed: {
    ...mapGetters(["isJEPA", "isJEP", "isCE", "isLCM"]),

    appName() {
      return "Meep";
    },

    footerClass() {
      return {
        "footer--ce": this.isCE,
        "footer--jep": this.isJEP,
        "footer--jepa": this.isJEPA,
        "footer--lcm": this.isLCM,
      };
    },

    organization() {
      return this.$store.state.self.organization;
    },
    hasOrganizationInfo() {
      return this.$store.state.self.organization;
    },
  },
};
</script>

<style lang="scss">
@import "../styles/index";

.footer {
  font-size: 11px;
  font-style: normal;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: $z-index-footer;
  background-color: var(--bg-third);
  height: 50px;
  color: var(--text-primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;

  &.footer--jep {
    background-color: var(--bg-alternate);
  }
  &.footer--ce {
    background-color: var(--bg-alternate);
  }

  &__copyright {
    opacity: 0.7;
  }

  @media (max-width: 600px) {
    font-size: 8px;
    position: relative;
  }

  a {
    color: var(--md-theme-default-text-primary-on-primary);
  }
}
</style>
