<template>
  <!-- eslint-disable -->
  <svg data-name="ffddd746-d782-4d7e-b013-ce097c30a5d8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.8 23.8"><g data-name="e6d0a49e-02b5-4f97-9aeb-de5f0a07bc3d"><g data-name="a333f55d-8613-47b7-8da4-712ea36d2ca7" fill="none"><circle cx="11.9" cy="11.9" r="11.4" stroke="#000" stroke-miterlimit="10"/><path stroke="#a22031" stroke-linecap="round" stroke-linejoin="round" d="M6.58 11.65l3.71 3.71 6.92-6.92"/></g></g></svg>
</template>

<script>
  export default {
    name : 'MeepIconTask'
  };
</script>
