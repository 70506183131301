<template>
<div class="loading-div" v-once>
   <svg class="circular" viewBox="25 25 50 50">
      <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="5" stroke-miterlimit="10"/>
   </svg>
</div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.loading-div {
    contain: content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px 0;

    .circular {
        animation: rotate 2s linear infinite;
        height: 60px;
        width: 60px;
        position: relative;
    }

    .path {
        stroke: #D31027;
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0;
        animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
        stroke-linecap: round;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes dash {
        0% {
            stroke-dasharray: 1,200;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 89,200;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 89,200;
            stroke-dashoffset: -124;
        }
    }
}
</style>
