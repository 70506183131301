<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.666 18.831"><defs/><g id="news_svg__Groupe_153" transform="translate(-25.703 -681.5)"><path id="news_svg__Tracé_18" d="M2442.008-826.293h16.342a2.966 2.966 0 002.966-2.966v-14.865h-16.234v13.967" transform="translate(-2412.447 1526.124)"/><path id="news_svg__Tracé_19" d="M2438.65-839.34v9.831a3.216 3.216 0 003.216 3.216 3.215 3.215 0 003.216-3.216v-9.831" transform="translate(-2412.447 1526.124)"/><path id="news_svg__Ligne_15" d="M0 0v9.674" transform="translate(29.419 686.784)"/><path id="news_svg__Rectangle_8" d="M0 0h3.487v5.83H0z" transform="translate(36.069 685.297)"/><path id="news_svg__Ligne_16" d="M0 0h4.007" transform="translate(42.17 685.788)"/><path id="news_svg__Ligne_17" d="M0 0h4.007" transform="translate(42.17 688.457)"/><path id="news_svg__Ligne_18" d="M0 0h4.007" transform="translate(42.17 691.127)"/><path id="news_svg__Ligne_19" d="M0 0h10.108" transform="translate(36.069 693.796)"/><path id="news_svg__Ligne_20" d="M0 0h10.108" transform="translate(36.069 696.466)"/></g></svg>
</template>

<script>
  export default {
    name : 'MeepIconNews'
  };
</script>
