<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.31 32.913"><path d="M24.977 27.636a.679.679 0 00-.679.679v2.564a.677.677 0 01-.677.677H2.035a.677.677 0 01-.678-.677v-21.9l7.62-7.62H23.62a.678.678 0 01.677.678v9.667a.679.679 0 001.357 0V2.037A2.035 2.035 0 0023.62.002H8.696a.679.679 0 00-.48.2L.198 8.22a.678.678 0 00-.2.48v22.182a2.035 2.035 0 002.035 2.034h21.586a2.034 2.034 0 002.034-2.034v-2.564a.679.679 0 00-.676-.682z"/><path d="M8.022 4.069v3.949H4.073a.679.679 0 000 1.357h4.628a.679.679 0 00.678-.679V4.068a.679.679 0 10-1.357 0zM32.714 14.426a2.021 2.021 0 00-1.439-.6H12.931a2.035 2.035 0 00-2.035 2.035v8.427a2.034 2.034 0 002.035 2.034h18.344a2.034 2.034 0 002.035-2.034v-8.427a2.022 2.022 0 00-.596-1.435zm-.761 9.866a.677.677 0 01-.678.677H12.931a.677.677 0 01-.677-.677v-8.427a.678.678 0 01.677-.678h18.344a.677.677 0 01.678.678z"/><path d="M17.212 17.089a.679.679 0 00-.679.679v3.234c0 .485-.51.71-.856.71a.679.679 0 000 1.357 2.152 2.152 0 002.213-2.067v-3.234a.679.679 0 00-.678-.679zM21.543 17.089H19.57a.679.679 0 00-.679.679v4.622a.679.679 0 001.357 0v-1.015h1.295a2.116 2.116 0 002.113-2.113v-.06a2.116 2.116 0 00-2.113-2.113zm.756 2.173a.756.756 0 01-.756.756h-1.3v-1.572h1.3a.756.756 0 01.756.756zM28.528 19.64h-1.047a.679.679 0 100 1.357h.368v.53a1.354 1.354 0 01-2.036-1.169V19.8a1.353 1.353 0 012.257-1.008.679.679 0 10.908-1.009 2.711 2.711 0 00-4.522 2.017v.558a2.711 2.711 0 004.527 2.012.679.679 0 00.224-.5v-1.548a.679.679 0 00-.679-.682z"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconCloudJpg'
  };
</script>
