<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.278 32.913"><path d="M20.638 17.743a.646.646 0 00-.007-.072c0-.021-.008-.042-.013-.062a.674.674 0 00-.045-.123c-.01-.02-.02-.04-.032-.059l-.036-.053a.657.657 0 00-.042-.051.639.639 0 00-.05-.048c-.01-.009-.017-.018-.027-.026s-.015-.009-.022-.014-.039-.026-.06-.038l-.056-.031c-.019-.01-.041-.017-.061-.024a.656.656 0 00-.065-.02c-.02 0-.04-.008-.061-.011a.584.584 0 00-.073-.007h-3.7a.679.679 0 100 1.357h2.269l-2.8 3.521a.679.679 0 00.531 1.1h3.675a.679.679 0 100-1.357h-2.269l2.8-3.521c.006-.007.009-.015.014-.022a.678.678 0 00.039-.06c.011-.019.021-.037.03-.056s.016-.041.024-.062a.475.475 0 00.03-.125.582.582 0 00.007-.073v-.063zM26.422 17.097H24.45a.679.679 0 00-.678.679v4.622a.679.679 0 101.357 0v-1.015h1.293a2.116 2.116 0 002.114-2.113v-.06a2.116 2.116 0 00-2.114-2.113zm.756 2.173a.757.757 0 01-.756.756h-1.293v-1.571h1.293a.757.757 0 01.756.756zM22.076 17.097a.679.679 0 00-.679.679v4.622a.679.679 0 001.357 0v-4.622a.679.679 0 00-.678-.679z"/><path d="M24.977 27.636a.679.679 0 00-.678.679v2.564a.678.678 0 01-.677.677H2.036a.678.678 0 01-.678-.677v-21.9l7.621-7.62h14.637a.677.677 0 01.677.677v9.668a.679.679 0 001.357 0V2.036A2.035 2.035 0 0023.615.001H8.691a.679.679 0 00-.48.2L.193 8.219a.679.679 0 00-.2.48v22.182a2.035 2.035 0 002.035 2.034h21.588a2.035 2.035 0 002.035-2.034v-2.564a.679.679 0 00-.674-.681z"/><path d="M8.018 4.069v3.949H4.069a.679.679 0 000 1.357h4.628a.679.679 0 00.678-.679V4.068a.679.679 0 10-1.357 0zM32.682 14.437a2.02 2.02 0 00-1.439-.6H12.899a2.035 2.035 0 00-2.034 2.034v8.427a2.035 2.035 0 002.034 2.035h18.344a2.036 2.036 0 002.035-2.035v-8.427a2.022 2.022 0 00-.596-1.434zm-.761 9.866a.678.678 0 01-.678.677H12.899a.678.678 0 01-.677-.677v-8.427a.678.678 0 01.677-.677h18.344a.678.678 0 01.678.677z"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconCloudZip'
  };
</script>
