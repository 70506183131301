import state from "./users/users.state";
import actions from "./users/users.actions";
import mutations from "./users/users.mutations";
import getters from "./users/users.getters";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
