<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.841 31.132"><path d="M12.048 7.408a.975.975 0 00-1.286.5L4.341 22.49a3.432 3.432 0 006.282 2.767l7.711-17.509a5.519 5.519 0 00-7.05-7.369c-.062.024-.122.049-.182.074a5.482 5.482 0 00-2.87 2.846L.64 20.54a7.547 7.547 0 1013.814 6.083l10.3-23.4a.975.975 0 10-1.785-.786l-10.3 23.4a5.6 5.6 0 11-10.245-4.512l7.593-17.241a3.569 3.569 0 116.532 2.877L8.839 24.47a1.482 1.482 0 01-2.713-1.195l6.422-14.582a.975.975 0 00-.5-1.285z"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconAttachment'
  };
</script>
